import React from 'react';
import { Row } from 'antd';

type ProjectUpdatesType = {
    nameid: string
}

const ProjectUpdates: React.FC<ProjectUpdatesType> = ({ nameid }) => {
    return <iframe src="https://www.trailblazerpl.com/#project-update" width='100%' height='500'></iframe>
};

export default ProjectUpdates;