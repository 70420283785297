import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import _ from 'lodash';
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Select } from 'antd';
import api from 'api';
import { get, post } from 'utils/fetch';
import { DebtRecord, DebtRecordItem, FilterItem, InvestmentItem, InvestmentRecord, InvestmentTypeEnum, NameItem } from 'types/investor';
import { Line, Area } from '@ant-design/plots';
import DollarCard from 'components/DollarCard';
import { AlignType } from 'rc-table/lib/interface';
import { FundLink } from 'components/Links';
import { USDollar } from 'utils/format';
import { useFiefAuth, useFiefIsAuthenticated, useFiefUserinfo } from '@fief/fief/react';
import { useLoginEmailContext } from 'components/Fief/Context';
import { getDividends } from './util';
import { TransactionGallery } from 'components/TransactionGallery';

import './Home.css';
import BasicInfo from 'pages/Property/BasicInfo';
import { CompanyInfo } from 'components/CompanyInfo';
import { AppHeader } from 'components/AppHeader';
import { isPhoneSize } from 'utils/util';
import ContributionCard from 'components/ContributionCard';
import { AccountCreation } from 'pages/AccountCreation/AccountCreation';
import RequireAuth from 'components/Fief/RequireAuth';
import { isAdmin } from 'utils/util';

const { Text } = Typography;

type InvestmentPieDataType = {
    type?: string,
    value?: number,
    "Invested Amount"?: number,
    "Account balance"?: number,
    fund?: string,
    amount?: number,
    percentage?: string
}

type InvestmentPieMouseEvent = {
    data: {
        data: InvestmentPieDataType
    }
}

type InvestmentPieProps = {
    data: InvestmentPieDataType[]
}

type HomeInvestmentTableItem = {
    amount: number
    entity_nameid: string,
    to_nameid: string,
    type: string,
    status: string,
    transaction_date?: string,
    contract_expiration_date?: string,
    dividend_interest_received?: number,
    principal_returned?: number,
    account_balance?: number,
    expected_return?: number,
    realized_return?: number,
    investment_id: number
}
// fund: key,
// amount: sumByFund[key].fundSum,
// percentage: sumByFund[key].percentage,
const InvestmentPie: React.FC<InvestmentPieProps> = ({ data }: InvestmentPieProps) => {
    const config = {
        width: isPhoneSize() ? window.innerWidth - 20 : 550,
        height: isPhoneSize() ? 250 : 250,
        autoFit: true,
        data,
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: { opacity: 0.5 },
        },
        angleField: "value",
        colorField: 'fund',
        innerRadius: 0.6,
        label: {
            content: "value",
            text: 'percentage',
            style: {
                fontWeight: 'bold',
            },
        },
        tooltip: {
            title: 'type'
        },
        legend: {
            color: {
                title: false,
                position: isPhoneSize() ? "bottom" : 'right',
            },
            offsetY: 150,
        },
        annotations: [
            {
                type: 'text',
                style: {
                    text: 'Investment\nbreakdown',
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    // fontSize: 40,
                    fontStyle: 'bold',
                },
            },
        ],
    };
    return <Pie
        {...config}
        onReady={(plot) => {
            plot.chart.on('interval:pointerover', (evt: InvestmentPieMouseEvent) => {
                plot.chart.emit('tooltip:show', {
                    data: { data: { type: evt.data.data.type } },
                });

                plot.chart.emit('element:highlight', {
                    data: { data: { type: evt.data.data.type } },
                });
            });
            plot.chart.on('interval:pointerout', (evt: InvestmentPieMouseEvent) => {
                plot.chart.emit('tooltip:hide', {
                    data: { data: { type: evt.data.data.type } },
                });
                plot.chart.emit('element:unhighlight', {
                    data: { data: { type: evt.data.data.type } },
                });
            });
        }}
    />;
};

type AreaItemType = {
    year: string;
    value: number;
}

const DemoArea = () => {
    const config = {
        data: [
            { year: '1991', value: 15468 },
            { year: '1992', value: 16100 },
            { year: '1993', value: 15900 },
            { year: '1994', value: 17409 },
            { year: '1995', value: 17000 },
            { year: '1996', value: 31056 },
            { year: '1997', value: 31982 },
            { year: '1998', value: 32040 },
            { year: '1999', value: 33233 },
        ],
        autoFit: true,
        width: 300,
        height: 300,
        xField: 'year',
        yField: 'value',
        shapeField: 'hvh',
        label: {
            text: 'value',
            style: {
                fontSize: 10,
                textAlign: (_: AreaItemType, idx: number, arr: AreaItemType[]) => {
                    if (idx === 0) return 'left';
                    if (idx === arr.length - 1) return 'right';
                    return 'center';
                },
            },
        },
        style: {
            opacity: 0.2,
        },
        axis: {
            y: { labelFormatter: '~s' },
        },
        line: {},
    };
    return <Area {...config} />;
};
interface HomeProps {
    setEmail: any;
    email: string | undefined;
    basicInfo: any;
    setBasicInfo: any;
    isOldUser: boolean;
}
const Home: React.FC<HomeProps> = ({ email, setEmail, basicInfo, setBasicInfo, isOldUser }) => {
    // const { email } = props
    // const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    // Active Investment Table
    const [investmentsInfo, setInvestmentsInfo] = useState<Record<string, any>>([]);
    const [closedInvestmentsInfo, setClosedInvestmentsInfo] = useState<Record<string, any>>([]);
    const [currentUser, setCurrentUser] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(true);
    const [displayInactiveFullTable, setDisplayInactiveFullTable] = useState<boolean>(false);
    const [activeInvestments, setActiveInvestments] = useState<InvestmentRecord[]>([]);
    const [closedInvestments, setClosedInvestments] = useState<InvestmentRecord[]>([]);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [debtRecord, setDebtRecord] = useState<DebtRecord[]>([]);
    const [debtRecordItems, setDebtRecordItems] = useState<DebtRecordItem[]>([]);
    const [debtsReceived, setDebtInterestReceived] = useState<number>(0);
    const [investorImage, setInvesetorImage] = useState<any>('');
    const [debtFilter, setDebtFilter] = useState<FilterItem[]>([]);
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const loginEmail = useLoginEmailContext()
    const [curExpandedInvestmentId, setCurExpandedInvestmentId] = useState<number>(-1)
    const [fundTypeToView, setFundTypeToView] = useState<string>("Both")
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    //console.log("home rendered")
    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach((e, i) => {
                updateObj[e] = data[i];
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }
        ));
        // setLoading(false);
    }
    //console.log("home is rendered")
    // Active Investment
    async function fetchInvestorInvestments(nameid: number) {
        //console.log("investor id", nameid)
        // setLoading(true);
        await get(api.investor.getActiveInvestments(nameid), async (data: InvestmentRecord[]) => {
            //console.log("fetchInvestorInvestments", data)
            // setInvestmentsInfo(data)
            const nameid_need_translation = [nameid.toString()]
            for (const record of data) {
                if (record.fund_name) record.to_nameid = record.fund_name;
                else if (record.fund_nameid) record.to_nameid = record.fund_nameid;
                else if (record.subfund_nameid) record.to_nameid = record.subfund_nameid;
                else if (record.holding_company_nameid) record.to_nameid = record.holding_company_nameid;
                else record.to_nameid = '';
                nameid_need_translation.push(record.to_nameid); // commented for testing purposes
            }
            setActiveInvestments(data);
            await fetchNameIDMapping(Array.from(new Set(nameid_need_translation))); // commented for testing purposes
        }, (errors) => {
            for (const err of errors) {
                displayErrorMessage(err.message);
            }
        });
    }
    //console.log("active investments", activeInvestments)
    // Closed Investment
    async function fetchInvestorClosedInvestments(nameid: number) {
        // setLoading(true);
        await get(api.investor.getActiveClosedInvestments(nameid), async (data: InvestmentRecord[]) => {
            //console.log("fetchInvestorInvestments", data)
            const nameid_need_translation = [nameid.toString()]
            for (const record of data) {
                if (record.fund_name) record.to_nameid = record.fund_name;
                else if (record.fund_nameid) record.to_nameid = record.fund_nameid;
                else if (record.subfund_nameid) record.to_nameid = record.subfund_nameid;
                else if (record.holding_company_nameid) record.to_nameid = record.holding_company_nameid;
                else record.to_nameid = '';
                nameid_need_translation.push(record.to_nameid); // commented for testing purposes
            }
            setClosedInvestments(data);
            await fetchNameIDMapping(Array.from(new Set(nameid_need_translation))); // commented for testing purposes
        }, (errors) => {
            for (const err of errors) {
                displayErrorMessage(err.message);
            }
        });
    }

    async function fetchTransactions(nameid: number) {
        await get(
            api.investor.getTransactions(nameid),
            setTransactions,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
    }

    async function fetchInvestorBasicInfo(email: string) {
        await get(
            api.investor.getBasicInfo(email),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
    }

    async function fetchDebtRecord(nameid: string) {
        // setLoading(true);
        await get(api.investor.getDebtRecord(nameid), (data) => {
            setDebtRecord(data);
            let receivedSum = 0;
            let filterUniqueNameIds = new Set<string>();
            let tableItems = [];
            for (const record of data as DebtRecord[]) {
                let tableItem: DebtRecordItem = {
                    from_nameid: '',
                    investor_nameid: record.investor_nameid,
                    transaction_date: record.transaction_date,
                    expense: record.expense,
                    income: record.income,
                    balance: record.balance,
                    category: record.category,
                };
                let nameid = ''
                if (record.fund_nameid) nameid = record.fund_nameid
                else if (record.subfund_nameid) nameid = record.subfund_nameid
                else if (record.holding_company_nameid) nameid = record.holding_company_nameid
                tableItem.from_nameid = nameid
                tableItems.push(tableItem);
                filterUniqueNameIds.add(nameid);

                receivedSum += record.expense;
            }
            setDebtRecordItems(tableItems);
            if (receivedSum < 0) receivedSum = -receivedSum;
            setDebtFilter(
                Array.from(filterUniqueNameIds).map(
                    (nameid: string) => {
                        let text = nameid;
                        if (nameid in nameidMapping) text = `${nameidMapping[nameid].name} (${nameid})`
                        return {
                            text,
                            value: nameid,
                        }
                    }
                )
            );
            setDebtInterestReceived(receivedSum);
            fetchNameIDMapping(Array.from(filterUniqueNameIds));
        }, (errors) => {
            for (const err of errors) {
                displayErrorMessage(err.message);
            }
        })
    }

    async function fetchInvestorInvestmentsTable(id: number) {
        await get(api.investor.getActiveInvestmentsTable(id), setInvestmentsInfo, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }))
    }

    async function fetchInvestorClosedInvestmentsTable(id: number) {
        await get(api.investor.getClosedInvestmentsTable(id),
            setClosedInvestmentsInfo, (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            }))
    }

    async function fetchCurrentUser() {
        //console.log("index.tsx-email:", email)
        return await 'e_00000021';
    }

    async function fetchData() {
        // perform API call to current user
        // Get nameid
        let nameid = await fetchCurrentUser()
        // await fetchInvestorBasicInfo(email!);
        // await fetchInvestorInvestments(nameid);

        // await fetchDebtRecord(nameid); might need in the future
        setLoading(false);
    }
    // 2
    useEffect(() => {
        async function callFetchData(basicInfo: Record<string, any>) {
            // linked from investment to property for pie chart
            // await fetchInvestorInvestments(basicInfo.id);
            // await fetchInvestorClosedInvestments(basicInfo.id);

            // linked investment and transaction for table
            await fetchInvestorInvestmentsTable(basicInfo.id);
            await fetchInvestorClosedInvestmentsTable(basicInfo.id);

            await fetchTransactions(basicInfo.id);
            fetchData()
        }
        if (Object.keys(basicInfo).length === 0) return
        callFetchData(basicInfo)
        // await fetchInvestorInvestments(nameid);
    }, [basicInfo]);
    // 1
    // useEffect(() => {
    //     async function callFetchInvestorBasicInfo() {
    //         // setLoading(true);
    //         await fetchInvestorBasicInfo(email!);
    //     }
    //     setLoading(true);
    //     if (email !== undefined) {
    //         callFetchInvestorBasicInfo();
    //     }
    // }, [email]);

    //console.log("transactions", transactions)
    const HomeInvestmentTableItemColumns = [{
        title: 'Name',
        dataIndex: 'entity_nameid',
    }, {
        title: 'Account',
        dataIndex: 'account',
    }, {
        title: 'To Fund',
        dataIndex: 'to_nameid',
        render: (nameid: string, record: HomeInvestmentTableItem) => {
            return <FundLink nameid={nameid} text={nameid in nameidMapping ? nameidMapping[nameid].name : nameid} />;
        },
    }, {
        title: 'Funding Date',
        dataIndex: 'transaction_date',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return new Date(a.transaction_date || '').valueOf() - new Date(b.transaction_date || '').valueOf();
        },
        render: (val: string) => new Date(val)?.toISOString()?.split('T')[0] || '-'
    }, {
        title: 'Closing Date',
        dataIndex: 'actual_redemption_date',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return new Date(a.transaction_date || '').valueOf() - new Date(b.transaction_date || '').valueOf();
        },
        render: (val: string) => new Date(val)?.toISOString()?.split('T')[0] || '-'
    }, {
        title: 'Type',
        dataIndex: 'type',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return a.type.localeCompare(b.type);
        },
        // }, {
        //     title: 'Status',
        //     dataIndex: 'status',
    }, {
        title: 'Invested Amount ($)',
        dataIndex: 'amount',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Dividends/Interests Received',
        dataIndex: 'dividend_interest_received',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Principal Returned',
        dataIndex: 'principal_returned',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Account Balance',
        dataIndex: 'account_balance',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
        // }, {
        //     title: 'Expected Return',
        //     dataIndex: 'expected_return',
        //     align: 'right' as AlignType,
        //     sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
        //         return (a.amount || 0) - (b.amount || 0);
        //     },
        //     render: (val: number) => USDollar.format(val)
    }, {
        title: 'Method',
        dataIndex: 'method'
    }];
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

    const handleExpand = (expanded: boolean, record: any) => {
        if (expanded) {
            setExpandedRowKeys([record.investment_id]);
        } else {
            setExpandedRowKeys([]);
        }
    };
    const renderHomeActiveInvestmentTable = () => {
        if (investmentsInfo.length === 0) return
        //console.log("renderHomeActiveInvestmentTable", investmentsInfo)
        // let data = [{
        //     entity_nameid: 'XYZ IRA',                   // holding_company.name
        //     to_nameid: 'Fund McClealland',              // fund.name
        //     amount: 100000,                             // investment.amount
        //     type: 'Equity',                             // investment.type
        //     status: 'Active',                           // investment.status
        //     dividend_interest_received: 0,
        //     principal_returned: 0,
        //     account_balance: 100000, // amount + dividend_interest_received so far
        //     expected_return: 100000, // amount + all dividend_interest_received so far
        //     transaction_date: '2023-10-27 00:00:00.000' // investment.transaction_date
        // }, {
        //     entity_nameid: 'ABC Invest LLC',
        //     to_nameid: 'Resnova',
        //     amount: 100000,
        //     type: 'Equity',
        //     status: 'Active',
        //     dividend_interest_received: 0,
        //     principal_returned: 0,
        //     account_balance: 100000,
        //     expected_return: 100000,
        //     transaction_date: '2023-11-28 00:00:00.000'
        // }, {
        //     entity_nameid: 'Xiaoming Huang',
        //     to_nameid: 'AlphaX Debt Fund',
        //     amount: 100000,
        //     type: 'Equity',
        //     status: 'Active',
        //     dividend_interest_received: 0,
        //     principal_returned: 0,
        //     account_balance: 100000,
        //     expected_return: 100000,
        //     transaction_date: '2024-02-14 00:00:00.000',
        // }]
        //console.log("investmentsInfo", investmentsInfo)
        let data = investmentsInfo.map((investment: any) => {
            return {
                entity_nameid: basicInfo.legal_name,
                account: investment.account_name,
                to_nameid: investment.fund_name,
                amount: investment.investment_amount,
                type: investment.fund_type,
                status: investment.investment_status,
                dividend_interest_received: investment.interest_received,
                principal_returned: investment.principal_returned,
                account_balance: investment.account_balance,
                // expected_return: 100000,
                transaction_date: investment.funding_receipt_date,
                actual_redemption_date: investment.actual_redemption_date,
                property_name: investment.address,
                investment_id: investment.investment_id,
                method: investment.investment_method[0].toUpperCase() + investment.investment_method.slice(1)
            }
        }).filter((investment: any) => {
            if (fundTypeToView === "Both") {
                return true
            } else {
                return investment.type.toLowerCase().includes(fundTypeToView.toLowerCase())
            }
        })

        if (data.length === 0) {
            return <div style={{ display: "none" }}></div>
        }

        return <Table
            bordered
            size='middle'
            dataSource={data}
            columns={HomeInvestmentTableItemColumns}
            pagination={false}
            rowKey={(record) => record.investment_id}
            expandable={{
                expandedRowRender: (record) => {
                    //console.log("HomeInvestmentTableItemColumns records:", record)
                    const curTransactions = transactions.filter(transactions =>
                        transactions.investment_id == record.investment_id).sort((a: any, b: any) => {
                            if (a.timestamp < b.timestamp) return -1;
                            return 1
                        })
                    return <div>
                        <ul style={{ listStyleType: 'none', margin: 0 }}>
                            <li style={{ margin: 0, display: "flex", paddingLeft: "30px" }}>
                                <div style={{ width: "100px", textAlign: "center" }}>Date</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Amount</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Category</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Status</div>
                            </li>
                        </ul>
                        <ul style={{ listStyleType: 'none', margin: 0 }}>
                            <TransactionGallery transactions={[...curTransactions].reverse()} basicInfo={basicInfo} />
                            {/* {[...curTransactions].reverse().map(transaction =>
                                //console.log(transaction)
                                <li style={{ margin: 0, display: "flex" }}>
                                    <div style={{ width: "100px", textAlign: "center" }}>{transaction.timestamp}</div>
                                    <div style={{ width: "100px", textAlign: "center" }}>{USDollar.format(transaction.amount)}</div>
                                    <div style={{ width: "100px", textAlign: "center" }}>{transaction.type}</div>
                                </li>
                            )} */}
                        </ul></div>
                },
                rowExpandable: (record) => true,
                expandedRowKeys,
                onExpand: handleExpand,
            }}
            summary={(rowData: readonly HomeInvestmentTableItem[]) => {
                return (
                    <Table.Summary fixed="bottom">
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={1} colSpan={7}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.amount).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={2}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.dividend_interest_received || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={3}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.principal_returned || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={4}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.account_balance || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    }

    const HomeInvestmentTableItemColumns2 = [{
        title: 'Name',
        dataIndex: 'entity_nameid',
    }, {
        title: 'Account',
        dataIndex: 'account',
    }, {
        title: 'To Fund',
        dataIndex: 'to_nameid',
        render: (nameid: string, record: HomeInvestmentTableItem) => {
            return <FundLink nameid={nameid} text={nameid in nameidMapping ? nameidMapping[nameid].name : nameid} />;
        },
    }, {
        title: 'Funding Date',
        dataIndex: 'transaction_date',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return new Date(a.transaction_date || '').valueOf() - new Date(b.transaction_date || '').valueOf();
        },
        render: (val: string) => new Date(val)?.toISOString()?.split('T')[0] || '-'
    }, {
        title: 'Closing Date',
        dataIndex: 'closing_date',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return new Date(a.transaction_date || '').valueOf() - new Date(b.transaction_date || '').valueOf();
        },
        render: (val: string) => new Date(val)?.toISOString()?.split('T')[0] || '-'
    }, {
        title: 'Type',
        dataIndex: 'type',
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return a.type.localeCompare(b.type);
        },
    }, {
        title: 'Invested Amount ($)',
        dataIndex: 'amount',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Dividends/Interests Received',
        dataIndex: 'dividend_interest_received',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.dividend_interest_received || 0) - (b.dividend_interest_received || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Principal Returned',
        dataIndex: 'principal_returned',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.principal_returned || 0) - (b.principal_returned || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Account Balance',
        dataIndex: 'account_balance',
        align: 'right' as AlignType,
        sorter: (a: HomeInvestmentTableItem, b: HomeInvestmentTableItem) => {
            return (a.account_balance || 0) - (b.account_balance || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, {
        title: 'Method',
        dataIndex: 'method'
    }];

    const renderHomeInactiveInvestmentTable = (summaryOnly = false) => {
        if (closedInvestmentsInfo.length === 0) return
        //console.log("closedInvestmentsInfo", closedInvestmentsInfo)
        let data = closedInvestmentsInfo.map((investment: any) => {
            return {
                entity_nameid: basicInfo.legal_name,
                account: investment.account_name,
                to_nameid: investment.fund_name,
                amount: investment.investment_amount,
                type: investment.fund_type,
                status: investment.investment_status,
                dividend_interest_received: investment.interest_received,
                principal_returned: investment.principal_returned,
                account_balance: investment.account_balance,
                // // expected_return: 100000,
                transaction_date: investment.funding_receipt_date,
                closing_date: investment.actual_redemption_date,
                property_name: investment.address,
                investment_id: investment.investment_id,
                method: investment.investment_method[0].toUpperCase() + investment.investment_method.slice(1)
            }
        }).filter((investment: any) => {
            if (fundTypeToView === "Both") {
                return true
            } else {
                return investment.type.toLowerCase().includes(fundTypeToView.toLowerCase())
            }
        })

        if (data.length === 0) {
            return <div style={{ display: "none" }}></div>
        }

        return <Table
            bordered
            size='middle'
            // className={summaryOnly ? 'summary-only-table' : ''}
            dataSource={data}
            columns={HomeInvestmentTableItemColumns2}
            pagination={false}
            rowKey={(record) => record.investment_id}
            expandable={{
                expandedRowRender: (record) => {
                    //console.log("HomeInvestmentTableItemColumns records:", record)

                    const curTransactions = transactions.filter(transactions =>
                        transactions.investment_id == record.investment_id).sort((a: any, b: any) => {
                            if (a.timestamp < b.timestamp) return -1;
                            return 1
                        })
                    return <div>
                        <ul style={{ listStyleType: 'none', margin: 0 }}>
                            <li style={{ margin: 0, display: "flex", paddingLeft: "30px" }}>
                                <div style={{ width: "100px", textAlign: "center" }}>Date</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Amount</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Category</div>
                                <div style={{ width: "100px", textAlign: "center" }}>Status</div>
                            </li>
                        </ul>
                        <ul style={{ listStyleType: 'none', margin: 0 }}>
                            <TransactionGallery transactions={[...curTransactions].reverse()} basicInfo={basicInfo} />
                        </ul></div>
                },
                rowExpandable: (record) => true,
                expandedRowKeys,
                onExpand: handleExpand,
            }}
            summary={(rowData: readonly HomeInvestmentTableItem[]) => {
                return (
                    <Table.Summary fixed="bottom">
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={1} colSpan={7}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.amount).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={2}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.dividend_interest_received || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={3}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.principal_returned || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={4}>
                                <Text type="success">
                                    {USDollar.format(rowData.map(data => data.account_balance || 0).reduce((a, b) => a + b))}
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    }
    let content = null;
    if (loading) content = <Spin style={{ marginTop: "30px" }} />;
    else {
        const groupedData = _.groupBy(activeInvestments, (record) => {
            return [record.to_nameid, record.type]
        })
        //console.log("groupedData", groupedData)
        let groupedDataWithSum: InvestmentItem[] = []
        for (const key of Object.keys(groupedData)) {
            const [to_nameid, type] = key.split(',')
            let groupInvestmentAmount = 0;
            let legal_name = ''
            let investor_nameid = ''
            let address: string | undefined = ""
            for (const item of groupedData[key]) {
                legal_name = String(item.legal_name);
                investor_nameid = item.investor_nameid;
                groupInvestmentAmount += item.investment_amount;
                address = item.address;
            }
            groupedDataWithSum.push({
                investor_nameid,
                legal_name,
                to_nameid,
                type,
                address,
                amount: groupInvestmentAmount,
                investment_amount: groupInvestmentAmount
            } as InvestmentItem)
        }

        //console.log("groupedDataWithSum", groupedDataWithSum)

        let pieData = groupedDataWithSum.map(({ to_nameid, type, investment_amount, address }) => {
            let name = nameidMapping[to_nameid]?.name || to_nameid
            return {
                // address,
                type: `${address} (${USDollar.format(investment_amount)})`,
                value: investment_amount,
                "Invested Amount": investment_amount
            }
        })
        // Calculate total value
        const total = pieData.reduce((sum, item) => sum + item.value, 0);
        //console.log("investmentsInfo", investmentsInfo)
        // total account balance
        const accountBalanceSum = _.sumBy(Object.values(investmentsInfo), 'account_balance');
        // group info by fund
        const groupByFund = _.groupBy(investmentsInfo, ({ fund_name }) => fund_name);
        // {fund, {sum, percent}}
        const sumByFund = _.mapValues(groupByFund, (investments) => {
            const fundSum = _.sumBy(investments, 'account_balance')
            const percentage = `${(fundSum / accountBalanceSum * 100).toFixed(2)}%`
            return {
                fundSum,
                percentage
            }
        })
        const resultArray = Object.keys(sumByFund).map(key => {
            return {
                fund: `${key} (${USDollar.format(sumByFund[key].fundSum)})`,
                "Account balance": sumByFund[key].fundSum,
                percentage: sumByFund[key].percentage,
                value: sumByFund[key].fundSum,
            };
        });
        //console.log("accountBalanceSum", accountBalanceSum)
        //console.log("res", sumByFund)



        // Add percentage to each data item
        const dataWithPercentage = pieData.map(item => ({
            ...item,
            percent: (item.value / total * 100).toFixed(2) + "%"
        }));
        // });
        //console.log("dataWithPercentage", dataWithPercentage)
        let totalActiveInvestment = 0
        let totalClosedInvestment = 0
        let totalDividentReceived = 0
        // let totalAccountBalance = 0
        // let totalPrincipalReturned = 0

        investmentsInfo.forEach((investment: any) => {
            totalActiveInvestment += investment.investment_amount;
            totalDividentReceived += investment.interest_received;
            // totalAccountBalance += investment.account_balance;
            // totalPrincipalReturned += investment.principal_returned;
        });

        closedInvestments.forEach((investment: any) => {
            totalClosedInvestment += investment.investment_amount;
            totalDividentReceived += investment.interest_received;
            // totalAccountBalance += investment.account_balance;
            // totalPrincipalReturned += investment.principal_returned;
        });

        const renderStats = () => <div className="stat">
            <Row gutter={[16, 24]} style={isPhoneSize() ? { justifyContent: "center" } : {}}>
                <Col>
                    <ContributionCard
                        title='Total Contributions'
                        amount={totalActiveInvestment + totalClosedInvestment}
                        activeAmount={totalActiveInvestment}
                        closedAmount={totalClosedInvestment}
                    />
                </Col>
                <Col>
                    <DollarCard
                        title='Total Dividends'
                        amount={totalDividentReceived}
                    />
                </Col>
                <Col>
                    <DollarCard
                        title='Total Projects Invested'
                        amount={investmentsInfo.length + closedInvestments.length}
                        isDollarFormat={false}
                    />
                </Col>
            </Row>
        </div>;

        const onTableCollapse = (keys: string | string[]) => {
            setDisplayInactiveFullTable(keys.includes('inactive_investment'));
        }

        content = <div style={isPhoneSize() ? {

        } : {
            overflowY: "scroll", overflowX: "hidden", scrollbarWidth: 'none', height: "calc(100vh - 150px)"
        }}>
            <Layout style={{ width: '100%', background: "transparent" }}>
                <h3 style={{ padding: "0px", color: "rgb(17 32 86)", margin: "0" }}>
                    {/* {basicInfo.legal_name} */}
                    Investment Breakdown
                </h3>
                <div style={{ display: 'flex', justifyContent: 'space-around', overflowX: "scroll", scrollbarWidth: "none" }}>
                    {/* <InvestmentPie data={dataWithPercentage} /> */}
                    <InvestmentPie data={resultArray} />
                    {/* <DemoArea /> */}
                </div>
                <Divider style={{ margin: "0px", marginBottom: "20px" }} />
                {renderStats()}
                <Divider style={{ margin: "20px" }} />
                <Select
                    onChange={(e) => setFundTypeToView(e)}
                    placeholder="Filter Fund Type"
                    style={{ width: '200px', background: "transparent" }}
                    optionFilterProp="children"
                    options={
                        [{
                            value: "Both",
                            label: "Both",
                        }, {
                            value: "Debt",
                            label: "Debt",
                        }, {
                            value: "Equity",
                            label: "Equity",
                        }]
                    }
                >
                </Select>
                <Collapse onChange={onTableCollapse} style={{ width: '100%' }} defaultActiveKey={['active_investment']} ghost items={[{
                    key: 'active_investment',
                    label: 'Active Investment',
                    children:
                        <div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
                            <div style={{ minWidth: '1500px' }}>
                                {renderHomeActiveInvestmentTable()}
                            </div>
                        </div>
                }, {
                    key: 'inactive_investment',
                    label: 'Closed Investment',
                    children:
                        <div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
                            <div style={{ minWidth: '1500px' }}>
                                {renderHomeInactiveInvestmentTable()}
                            </div>
                        </div>
                },]} />

            </Layout>
        </div>
    }
    return <>
        {Object.keys(basicInfo).length !== 0 && basicInfo.legal_name === "Visitor" && !isOldUser && <AccountCreation basicInfo={basicInfo} setBasicInfo={setBasicInfo} />}

        {
            isOldUser &&
            <RequireAuth setEmail={setEmail}>
                <div style={isPhoneSize() ? {
                    display: "flex", flexDirection: 'column', width: "100%"
                } : {
                    display: "flex", flexDirection: 'column', width: "100%"
                }}>
                    {!isPhoneSize() && <AppHeader basicInfo={basicInfo} setEmail={setEmail} />}
                    {contextHolder}
                    {content}
                </div>
            </RequireAuth>
        }
    </>
}

export default Home;
