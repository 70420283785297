import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import _ from 'lodash';
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Tabs, Button, Card } from 'antd';
import api from 'api';
import { get, post } from 'utils/fetch';
import { DebtRecord, DebtRecordItem, FilterItem, InvestmentItem, InvestmentRecord, InvestmentTypeEnum, NameItem } from 'types/investor';
import { Line } from '@ant-design/plots';
import DollarCard from 'components/DollarCard';
import { AlignType } from 'rc-table/lib/interface';
import { FundLink } from 'components/Links';
import { USDollar } from 'utils/format';
import Paragraph from 'antd/es/typography/Paragraph';
import ProjectDetails from './ProjectDetails';
import ProjectDocuments from './ProjectDocuments';
import ProjectUpdates from './ProjectUpdates';
const { Text } = Typography;

const { Header, Content, Sider } = Layout;


const Offering: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const [activeTabKey, setActiveTabKey] = useState<string>('details');
    const [fundID, setFundID] = useState<number>(0);
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach((e, i) => {
                updateObj[e] = data[i];
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }
        ));
        setLoading(false);
    }


    async function fetchData() {
        setLoading(true);
        // perform API call to current user
        // Get nameid
        // let nameid = await fetchCurrentUser()
        // await fetchInvestorBasicInfo(nameid);
        // await fetchInvestorInvestments(nameid);
        // await fetchDebtRecord(nameid);
        setLoading(false);
    }


    useEffect(() => {
        fetchData();
    }, []);


    const tabItems = [
        {
            key: 'details',
            label: 'Details ',
            children: <ProjectDetails fund_id={fundID} nameid='dddd' />,
        },
        {
            key: 'documents',
            label: 'Documents',
            children: <ProjectDocuments nameid='ddddd' />,
        },
        {
            key: 'updates',
            label: 'Updates',
            children: <ProjectUpdates nameid='ddddd' />,
        },
    ];

    let content = null;
    if (loading) content = <Spin />;
    else {

        const generateDivs = (items: Array<string[]>) => items.map(item => <div key={item[0] + item[1]} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{item[0]}</span>
            <span>{item[1]}</span>
        </div>)

        content = <Layout style={{ width: '100%', justifyContent: 'flex-start' }}>
            <Content>
                <h1 style={{ width: '100%' }}>Trailblazerpl Place</h1>
                <Paragraph>Garden-style community originally constructed in 2015. This garden-style community is in the fastest growing industrial submarkets in the nation</Paragraph>
                <div className='tabs'>
                    <Tabs
                        tabBarStyle={{ width: '100%' }}
                        defaultActiveKey="details"
                        tabPosition="top"
                        activeKey={activeTabKey}
                        onChange={(val) => setActiveTabKey(val)}
                        items={tabItems}
                    />
                </div>
            </Content>
            <Sider style={{ background: 'white' }}>
                <Layout>
                    <img src="https://static.wixstatic.com/media/1e11ac_36f69283118045428e358e85a16768eb~mv2.jpg/v1/fill/w_1568,h_1080,al_c,q_85,enc_auto/1e11ac_36f69283118045428e358e85a16768eb~mv2.jpg" />
                    <Button style={{ marginTop: 20 }} type="primary">Invest Now</Button>

                    <Card style={{ marginTop: 30, }} title="Key Information">
                        PROJECT DETAILS
                        {generateDivs([
                            ['Investment Profile', 'AlphaX Debt Fund'],
                            ['Property Type', 'Multifamily'],
                            ['Acquisition Cost', '$12.7 million'],
                            ['Closing Date', '2023-10-11'],
                        ])}
                        <Divider />
                        INVESTMENT DETAILS
                        {generateDivs([
                            ['Target Investor IRR', '16%'],
                            ['Cash yielf', '5.5%'],
                            ['Target Investment Period', '6 Years'],
                            ['Minimum Investment', '$50,000'],
                            ['Distribution Period', 'Quarterly'],
                            ['Offering Due', '2024-04-12'],
                            ['Expected Return', '10%'],
                        ])}
                    </Card>
                </Layout>
            </Sider>
        </Layout>;
    }
    return <>
        {contextHolder}
        {content}
    </>
}

export default Offering;
