import backArrow from "../../assets/img/backArrow.png"
import { Spin, message } from "antd"
import { useState, useEffect } from "react"
import { get } from "utils/fetch"
import api from "api"
import { USDollar } from "utils/format"

export function InterestManager(props) {
    const { setView, investmentId } = props
    const [loading, setLoading] = useState(true)
    const [interests, setInterests] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [interestMap, setInterestMap] = useState({})
    const [saveButtonText, setSaveButtonText] = useState("Save")
    const [saveButtonWidth, setSaveButtonWidth] = useState("50px")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPrevInterest(id) {
        await get(api.manager.interestByInvestment(id), setInterests, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    useEffect(() => {
        // set id -> amount as key value pair
        setInterestMap(
            interests
                .reduce((acc, cur) => {
                    acc[cur.id] = cur.amount
                    return acc
                }, {})
        )
        // console.log(map)
    }, [interests])

    useEffect(() => {
        fetchPrevInterest(investmentId)
    }, [investmentId])
    console.log(interestMap)

    async function saveInterest() {
        if (saveButtonText === "Saving") {
            return
        }
        setSaveButtonText("Saving")
        setSaveButtonWidth("80px")

        // upload new interests
        const body = JSON.stringify({
            interestMap
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        await fetch(api.manager.updateInterests, options); // new record

        setSaveButtonText("Save")
        setSaveButtonWidth("50px")
    }

    return <>
        <div>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView("investment")}
                ></img>
            </div>
        </div>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading &&
            <div>
                <div style={entryStyle}>
                    <div style={{ display: "flex", fontSize: "17px" }}>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Time
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Amount
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Dollar Amount
                        </div>
                        <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            Status
                        </div>
                    </div>
                    <div style={{ border: "1px solid black", width: "600px" }}>
                        {interests.map((interest, idx) => <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {interest.timestamp}
                            </div>
                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                <input
                                    value={interestMap[interest.id] || 0}
                                    style={{ ...inputStyle, width: "150px", textAlign: "center" }}
                                    onChange={e => setInterestMap({ ...interestMap, [parseInt(interest.id)]: parseFloat(e.target.value) || 0 })}>
                                </input>
                            </div>
                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {USDollar.format(interestMap[interest.id])}
                            </div>
                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                {interest.status}
                            </div>
                        </div>)}
                    </div>
                </div>
                <button style={{
                    height: "30px",
                    width: saveButtonWidth,
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveInterest}>
                    {saveButtonText}
                </button>
            </div>
        }
    </>
}