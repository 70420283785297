import React from 'react';
import { useLocation } from 'react-router-dom';

const NotFound: React.FC = () => {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for requested URL <code>{location.pathname}</code>
            </h3>
            {location.pathname.includes('home') ? <p>Are you looking for <a href='/home'>Home Page</a></p> : null }
            {location.pathname.includes('dashboard') ? <p>Are you looking for <a href='/home'>Home Page</a></p> : null }
            {location.pathname.includes('property') ? <p>Are you looking for <a href='/property'>Property Page</a></p> : null }
            {location.pathname.includes('investor') ? <p>Are you looking for <a href='/investor'>Investor Page</a></p> : null }
            {location.pathname.includes('fund') ? <p>Are you looking for <a href='/fund'>Fund Page</a></p> : null }
            {location.pathname.includes('setting') ? <p>Are you looking for <a href='/settings'>Settings Page</a></p> : null }
        </div>
    );
}

export default NotFound;
