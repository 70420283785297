import React from 'react';
import { Button, Checkbox, Form, type FormProps, Input } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};


const Login: React.FC = () => {
    const navigate = useNavigate();
    const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
        if (values.username === 'admin' && values.password === 'admin') {
            // TODO: Make request to backend to obtain value JWT auth token
            localStorage.setItem('token', 'yes');
            navigate('/home');
        }
    };
    
    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    };

    // if (isLoggedIn) return <Navigate to="/home" />
    
    return <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item<FieldType>
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item<FieldType>
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
        >
            <Input.Password />
        </Form.Item>

        <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
        >
            <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>

};

export default Login;