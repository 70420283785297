import { CompanyInfo } from "components/CompanyInfo"
import { useState, useEffect } from "react"
import { message } from 'antd';
import { post } from "utils/fetch"
import { useFiefAuth, useFiefIsAuthenticated, useFiefUserinfo } from '@fief/fief/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isPhoneSize } from "utils/util";

export function AccountCreation(props) {
    const { basicInfo, setBasicInfo } = props
    const [messageApi, contextHolder] = message.useMessage();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [preferredName, setPreferredName] = useState("")
    const [phone, setPhone] = useState("")
    const [res, setRes] = useState(null)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const fiefAuth = useFiefAuth();
    const isAuthenticated = useFiefIsAuthenticated();
    const userinfo = useFiefUserinfo();
    const navigate = useNavigate();

    const logout = useCallback(async () => {
        try {
            // Get the base URL of your application
            const baseUrl = `${window.location.protocol}//${window.location.host}`;

            // Perform Fief logout with redirect URL
            await fiefAuth.logout(baseUrl + '/logout');
        } catch (error) {
            console.error("Fief logout failed:", error);
        } finally {
            // Clear local storage
            localStorage.removeItem('token');
            // Clear any other auth-related state here

            // Navigate to login page
            navigate('/logout');
        }
    }, [fiefAuth, navigate]);

    const inputBoxWidth = 300
    const buttonWidth = 120

    const keyStyle = {
        color: "rgb(17 32 86)",
        fontSize: "20px"
    }
    const inputBoxStyle = {
        width: `${inputBoxWidth}px`,
        height: "30px",
        borderRadius: "20px",
        margin: '20px 0',
        background: "transparent",
        padding: "0 10px"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    function canSubmit() {
        var invalidFields = []
        if (firstName.trim().length === 0) {
            invalidFields.push("Please fill in first name.")
        }
        if (lastName.trim().length === 0) {
            invalidFields.push("Please fill in last name.")
        }
        if (phone.trim().length === 0) {
            invalidFields.push("Please fill in phone.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    function submitUserInfo() {
        if (canSubmit() === false) {
            return
        }
        post(`/api/investor/insert`,
            { email: basicInfo.email, firstName, lastName, phone },
            setRes,
            (errors) => errors.forEach(err => {
                displayErrorMessage(err.message);
            }))
    }
    console.log(res)
    useEffect(() => {
        if (res === null) return
        setBasicInfo({
            ...basicInfo,
            legal_name: firstName + " " + lastName,
            name: firstName + " " + lastName,
            phone: phone,
        })
    }, [res])
    //console.log(firstName, firstName.length)
    //console.log(phone)
    return <>
        <div style={isPhoneSize() ? {
            display: "flex", flexDirection: "column", height: "100vh"
        } : {
            display: "flex", flexDirection: "row", height: "100vh"
        }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {/* <h1 style={{ color: "rgb(17 32 86)" }}>
                    Please input your information
                </h1> */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{}}>
                        {creationErrorMessages.map((message, idx) => {
                            return <div key={idx} style={{ color: "red" }}>{message}</div>
                        })}

                        <h1 style={{ color: "rgb(17 32 86)" }}>
                            Please input your information
                        </h1>
                        {/* ---------------------------- */}
                        <div style={keyStyle}>
                            First name <span style={{ color: "red" }}>*</span>
                        </div>

                        <input onInput={e => setFirstName(e.target.value.trim())} style={inputBoxStyle}></input>

                        {/* ---------------------------- */}
                        <div style={keyStyle}>
                            Last name <span style={{ color: "red" }}>*</span>
                        </div>
                        <input onInput={e => setLastName(e.target.value)} style={inputBoxStyle}></input>

                        {/* ---------------------------- */}
                        <div style={keyStyle}>
                            Phone number <span style={{ color: "red" }}>*</span>
                        </div>
                        <input onInput={e => setPhone(e.target.value)} style={inputBoxStyle}></input>

                        {/* ---------------------------- */}
                        <div style={{ display: "flex" }}>
                            <div>
                                <button style={{
                                    width: `${buttonWidth}px`,
                                    height: "30px",
                                    borderRadius: "30px",
                                    background: "rgb(17 32 86)",
                                    color: "white",
                                    cursor: "pointer",
                                    marginTop: "20px"
                                }}
                                    onClick={submitUserInfo}>Submit</button>
                            </div>
                            <div>
                                <button style={{
                                    width: `${buttonWidth}px`,
                                    height: "30px",
                                    borderRadius: "30px",
                                    background: "rgb(17 32 86)",
                                    color: "white",
                                    cursor: "pointer",
                                    marginTop: "20px",
                                    marginLeft: `${inputBoxWidth - buttonWidth * 2}px`
                                }}
                                    onClick={logout}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={isPhoneSize() ? {} : { width: "290px" }}>

                <CompanyInfo />
            </div>
        </div>

    </>
}