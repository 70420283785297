import React, { useEffect, useState } from 'react';
import { NameItem } from 'types/investor';
import { TabComponentProps } from 'types/common';
import { Card, Col, Row, Space, Spin, message } from 'antd';
import './InvestedProperties.css';
import _ from 'lodash';
import api from 'api';
import { get, post } from 'utils/fetch';

const InvestedProperties: React.FC<TabComponentProps> = ({ nameid }) => {
    const [weights, setWeights] = useState<Record<string, number>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchWeightToProperties(nameid: string) {
        setLoading(true);
        await get(api.investor.weightToProperties(nameid), (data) => {
            setWeights(data);
            fetchNameIDMapping(Object.keys(data));
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    }
    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach(function (e, i) {
                updateObj[e] = data[i]
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchWeightToProperties(nameid);
        }
    }, [nameid]);

    if (loading) return <Spin />
    else if (!nameid) return <span>Please select a valid user</span>;


    let properties = [];
    for (const nameid in weights) {
        if (nameid.toLowerCase().startsWith('a-')) {
            let thisname = nameid;
            if (nameid in nameidMapping) thisname = nameidMapping[nameid].name;
            properties.push({
                address: thisname,
                ownership: weights[nameid],
                nameid
            })
        }
    }

    const displayData = [];
    let propertyData = _.cloneDeep(properties);
    while (propertyData.length) displayData.push(propertyData.splice(0, 3));

    return <div className='invested-properties-container'>
        <Space direction='vertical' size={16} className="invested-properties">
            {contextHolder}
            {displayData.map((x, i) =>
                <Row gutter={16} key={i}>
                    {
                        x.map(y => <>
                            <Col key={y.address} span={8}>
                                <Card onClick={() => {
                                    window.open(`/property?nameid=${y.nameid}`)
                                }} style={{ background: '#eeeeee' }} hoverable title={y.address} bordered={false}>
                                    Ownership: {typeof y.ownership === "string" ?
                                        y.ownership :
                                        Number(y.ownership * 100).toFixed(2)
                                    }%
                                </Card>
                            </Col>
                        </>)
                    }
                </Row>
            )}
        </Space>
    </div>
}

export default InvestedProperties;