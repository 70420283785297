import { useEffect, useState } from "react"
import backArrow from "../../assets/img/backArrow.png"
import api from "api"
import { get } from "utils/fetch"
import { Select, Space, Spin, message } from "antd"
import { DeleteConfirmation } from "components/DeleteConfirmation"

export function InvestorInsertion(props) {
    const { investor, setInvestorId, accounts } = props
    const [messageApi, contextHolder] = message.useMessage();
    const [dirty, setDirty] = useState(false)
    const [saving, setSaving] = useState(false)
    const [accountsHeld, setAccountsHeld] = useState([])
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [accountIdSelected, setAccountIdSelected] = useState([])
    const [firstName, setFirstName] = useState(investor === null || investor.first_name === null ? "" : investor.first_name)
    const [lastName, setLastName] = useState(investor === null || investor.last_name === null ? "" : investor.last_name)
    const [email, setEmail] = useState(investor === null || investor.email === null ? "" : investor.email)
    const [phone, setPhone] = useState(investor === null || investor.phone === null ? "" : investor.phone)
    const [showDeletionBox, setShowDeletionBox] = useState(false)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    var account2id = {}
    for (const account of accounts) {
        account2id[account.account_name] = account.id
    }

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (firstName.trim().length === 0) {
            invalidFields.push("Please fill in first name.")
        }
        if (lastName.trim().length === 0) {
            invalidFields.push("Please fill in last name.")
        }
        if (email.trim().length === 0) {
            invalidFields.push("Please fill in phone.")
        }
        if (phone.trim().length === 0) {
            invalidFields.push("Please fill in phone.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function fetchAccountsByInvestor(id) {
        await get(api.manager.accountsByInvestor(id), setAccountsHeld, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        //console.log("Investor Insertion Rendered.")
        if (investor === null) {
            setLoading(false)
            return
        }
        fetchAccountsByInvestor(investor.id)
    }, [investor])

    // console.log(accountsHeld.map(account => account.account_name))

    async function saveInvestor() {
        //console.log(canSubmit())
        if (canSubmit() === false) {
            return
        }
        //console.log("idDirty", dirty)
        if (dirty === false) {
            setInvestorId(0)
            return
        }
        setSaving(true)
        setFirstName(firstName.trim())
        setLastName(lastName.trim())
        setEmail(email.trim())
        setPhone(phone.trim())

        // account id held
        const accountIdsHeld = accounts.length === 0 ? [] : accountsHeld.map(account => account.id)
        // newly added account idx (selected but not previously held)
        const addedAccountIds = accountIdSelected.length === 0 ? [] : accountIdSelected.filter(id => !accountIdsHeld.includes(id));
        // removed property idx (previously invested but not selected)
        const removedAccountIds = accountIdsHeld.length === 0 ? [] : accountIdsHeld.filter(id => !accountIdSelected.includes(id))
        console.log(accountIdsHeld, addedAccountIds, removedAccountIds)
        const option = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                phone,
                addedAccountIds,
                removedAccountIds
            }),
        }
        if (investor === null) {
            await fetch(api.manager.investors, option);
        } else {
            await fetch(api.manager.investor(investor.id), option);
        }
        setInvestorId(0)
    }

    const handleChange = (accounts) => {
        setDirty(true)
        setAccountIdSelected(accounts.map(account => account2id[account]))
    };
    const selectionOptions = accounts
        .map(account => {
            return {
                label: account.account_name,
                value: account.account_name,
                desc: account.account_name
            }
        })

    function deleteInvestor() {
        if (accountsHeld.length > 0) {
            setCreationErrorMessages([
                ...accountsHeld
                    .map(account => {
                        return `Current investor is holding ${account.account_name}.`
                    })
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.investor(investor.id), options);
        const data = await result.json()
        setInvestorId(0)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

        {!loading && <div>

            {investor !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Investor: ${investor.legal_name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setInvestorId(0)}
                ></img>
            </div>
            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}
            <div style={entryStyle}>
                First Name<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                    value={firstName}
                    onInput={(e) => {
                        setFirstName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                Last Name<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                    value={lastName}
                    onInput={(e) => {
                        setLastName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                Email<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                    value={email}
                    onInput={(e) => {
                        setEmail(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                Phone Number<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                    value={phone}
                    onInput={(e) => {
                        setPhone(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Accounts: </div>
                    <span>
                        <Select
                            mode="multiple"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select accounts"
                            defaultValue={accountsHeld.map(account => account.account_name)}
                            onChange={handleChange}
                            options={selectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>
            </div>

            {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveInvestor}
                >
                    Save
                </button>
                {investor !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteInvestor}
                >
                    Delete
                </button>}
            </div>
            }
            {saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                >
                    Saving
                </button>
            </div>}
        </div>}
    </>
}