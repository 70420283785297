import React, { useEffect, useState } from 'react';
import { InvestmentRecord, InvestmentTypeEnum, NameItem } from 'types/investor';
import { TabComponentProps } from 'types/common';
import { AlignType } from 'rc-table/lib/interface';
import { Spin, Table, message, Typography, Divider } from 'antd';
import _ from 'lodash';
import api from 'api';
import { get, post } from 'utils/fetch';
import { USDollar } from 'utils/format';
import { FundLink } from 'components/Links';

const { Text } = Typography;


const InvestmentHistory: React.FC<TabComponentProps> = ({ nameid }) => {
    const [investments, setInvestments] = useState<InvestmentRecord[]>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    async function fetchInvestorInvestments(nameid: string) {
        setLoading(true);
        await get(api.investor.getInvestments(nameid), (data) => {
            const nameid_need_translation = [nameid];
            for (const record of data) {
                if (record.fund_nameid) record.to_nameid = record.fund_nameid;
                else if (record.subfund_nameid) record.to_nameid = record.subfund_nameid;
                else if (record.holding_company_nameid) record.to_nameid = record.holding_company_nameid;
                else record.to_nameid = '';
                nameid_need_translation.push(record.to_nameid);
            }
            setInvestments(data);
            fetchNameIDMapping(Array.from(new Set(nameid_need_translation)));
        }, (errors) => {
            for (const err of errors) {
                displayErrorMessage(err.message);
            }
        });
    }

    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach(function (e, i) {
                updateObj[e] = data[i]
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        })
        );
        setLoading(false);
    }
    const investmentColumns = [{
        title: 'Name',
        dataIndex: 'legal_name',
        width: '15%',
    }, {
        title: 'To Fund',
        dataIndex: 'to_nameid',
        width: '25%',
        render: (value: string, record: InvestmentRecord, index: number) => {
            let nameid = '-'
            if (record.fund_nameid) nameid = record.fund_nameid
            else if (record.subfund_nameid) nameid = record.subfund_nameid
            else if (record.holding_company_nameid) nameid = record.holding_company_nameid
            return <FundLink nameid={nameid} text={nameid in nameidMapping ? nameidMapping[nameid].name : nameid} />;
        }
    }, {
        title: 'Funding Date',
        dataIndex: 'transaction_date',
        sorter: (a: InvestmentRecord, b: InvestmentRecord) => {
            return new Date(a.transaction_date).valueOf() - new Date(b.transaction_date).valueOf();
        },
        render: (val: string) => new Date(val).toISOString().split('T')[0]
    }, {
        title: 'Type',
        dataIndex: 'type',
    }, {
        title: 'Status',
        dataIndex: 'status',
        sorter: (a: InvestmentRecord, b: InvestmentRecord) => {
            if (a.status.toLowerCase() === 'active') return 1
            return -1
        },
        defaultSortOrder: 'descend' as const,
    }, {
        title: 'Investment Entity',
        dataIndex: 'investment_entity'
    }, {
        title: 'Investment Amount ($)',
        dataIndex: 'amount',
        align: 'right' as AlignType,
        sorter: (a: InvestmentRecord, b: InvestmentRecord) => {
            return (a.amount || 0) - (b.amount || 0);
        },
        render: (val: number) => USDollar.format(val)
    }, ];

    useEffect(() => {
        if (nameid) {
            fetchInvestorInvestments(nameid);
        }
    }, [nameid]);

    if (loading) return <Spin />
    else if (!nameid) return <span>Please select a valid user</span>;

    return <>
        {contextHolder}
        <h3>Equity Invested</h3>
        <Table
            bordered
            pagination={false}
            dataSource={investments.filter(x => x.type === InvestmentTypeEnum.Equity)}
            columns={investmentColumns}
            summary={(rowData: readonly InvestmentRecord[]) => {
              let totalDebt = 0;
              let totalEquity = 0;
              let totalInvested = 0;
      
              rowData.forEach(({ type, amount }) => {
                if (type === InvestmentTypeEnum.Equity) {
                    totalEquity += amount;
                } else if (type === InvestmentTypeEnum.Debt) {
                    totalDebt += amount;
                }
                totalInvested += amount;
              });

              if (totalEquity < 0) totalEquity = -totalEquity;
      
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total Equity Invested</Table.Summary.Cell>
                    <Table.Summary.Cell align='right' index={1} colSpan={6}>
                      <Text type="success">{USDollar.format(totalEquity)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
        />
        <Divider />
        <h3>Debt Invested</h3>
        <Table
            bordered
            pagination={false}
            dataSource={investments.filter(x => x.type === InvestmentTypeEnum.Debt)}
            columns={investmentColumns}
            summary={(rowData: readonly InvestmentRecord[]) => {
              let totalDebt = 0;
      
              rowData.forEach(({ type, amount }) => {
                if (type === InvestmentTypeEnum.Debt) {
                    totalDebt += amount;
                }
              });

              if (totalDebt < 0) totalDebt = -totalDebt;
      
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total Debt Invested</Table.Summary.Cell>
                    <Table.Summary.Cell align='right' index={1} colSpan={6}>
                      <Text type="success">{USDollar.format(totalDebt)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
        />
    </>
}

export default InvestmentHistory;