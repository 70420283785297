import { useEffect, useState } from "react"
import { isAdmin, isPhoneSize } from "utils/util"
import { Spin, message } from "antd";
import api from "api";
import { get } from "utils/fetch";
import PdfIcon from "../../assets/img/PdfIcon.png"

export function EmailHistory(props) {

    const [emails, setEmails] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchEmails() {
        await get(api.email.emails, setEmails, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }
    console.log(emails)
    useEffect(() => {
        fetchEmails()
    }, [])

    function convertAndFormatDatetime(dateStr) {
        // Parse the ISO 8601 datetime string
        const date = new Date(dateStr);

        // Format the datetime to the desired format: 'October 7, 2024, 10:35 AM'
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZoneName: 'short' // Optional: adds timezone abbreviation
        };

        // Get system's local timezone format using Intl.DateTimeFormat
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    }

    return <>
        {!isAdmin() && <div>Not Authorized</div>}
        {loading && isAdmin() && <div style={{ marginTop: "30px", textAlign: "center", height: "100vh" }}><Spin /></div>}
        {!loading && isAdmin() &&
            <div style={{ width: "400px", height: "100vh", overflowY: "scroll", scrollbarWidth: "none" }}>
                <div style={{ margin: "50px 0" }}>
                    {emails.map((email, id) => <div key={id} style={{ border: "1px solid black", fontSize: "15px", borderTop: id > 0 ? "" : "1px solid black", padding: "10px 20px" }}>
                        <div style={{ display: "flex", flexDirection: "row", margin: "5px 0" }}>
                            To: {
                                <div style={{ marginLeft: "5px" }}>
                                    {
                                        email.recipients.map((recipient, id) =>
                                            <div key={id}>
                                                {id > 0 && <div>,</div>}
                                                {" " + recipient.recipient_email}
                                                {" " + recipient.recipient_name}
                                            </div>)
                                    }
                                </div>
                            }
                        </div>

                        <div style={{ margin: "5px 0" }}>
                            Subject: {email.subject}
                        </div>

                        <div style={{ margin: "5px 0" }}>
                            Content: {email.content}
                        </div>

                        <div style={{ margin: "5px 0" }}>
                            Sent At: {convertAndFormatDatetime(email.created_at + "Z")}
                        </div>

                        <div style={{ margin: "5px 0" }}>
                            Attachments: {
                                email.attachments.map((attachment, id) => {
                                    return <a key={id} href={attachment.file_path} target="_blank" rel="noreferrer noopener">
                                        <div key={id} style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black", paddingLeft: "50px" }}>
                                            <div style={{ padding: "5px", paddingBottom: "0" }}>
                                                <img src={PdfIcon} style={{ width: "15px" }}></img>
                                            </div>
                                            {attachment.file_path.substring(attachment.file_path.lastIndexOf('/') + 1)}
                                        </div>
                                    </a>
                                })
                            }
                        </div>
                    </div>)}
                </div>
            </div>
        }
    </>
}