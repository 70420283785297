import { DescriptionsProps } from "antd/es/descriptions";

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const makeDescriptionItems = (infoKeyNameMap: Record<string, string>, data: Record<string, any>, keyConfig?: Record<string, string>) =>
    Object.keys(infoKeyNameMap).filter(key => key in data).map(key => {
        return {
            key, label: infoKeyNameMap[key], children: data[key] || '-'
        }
    });

export const makeTableDataSource = (infoKeyNameMap: Record<string, string>, data: Record<string, any>, keyConfig?: Record<string, string>) =>
    Object.keys(infoKeyNameMap).filter(key => key in data).map(key => {
        // if (key in keyConfig) {

        // }
        return {
            key, name: infoKeyNameMap[key], value: data[key] || '-'
        }
    });

export const makeColumns = (nameTitle: string, valueTitle: string) => [{
    title: nameTitle,
    dataIndex: 'name'
}, {
    title: valueTitle,
    dataIndex: 'value'
}];
