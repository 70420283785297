import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, message, AutoComplete, Input, Tabs, Layout, Select } from "antd";
import { DefaultOptionType } from 'rc-select/lib/Select';
import { FundData } from 'types/fund';

import api from 'api';
import { get } from 'utils/fetch';
import BasicInfo from './BasicInfo';
import Investor from './Investor';
import Projects from 'pages/Fund/Projects';

import './fund.css';

interface FundProps {
    email: string | undefined;
    setEmail: any
}

const Fund: React.FC<FundProps> = ({ email, setEmail }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [funds, setFunds] = useState<FundData[]>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('basic_info');
    const [fundNameIDInputValue, onFundNameIDInputValueChange] = useState<string>('');
    const [fundNameID, setFundNameID] = useState<string>('');
    const [fundID, setFundID] = useState<number>(0);
    const [messageApi, contextHolder] = message.useMessage();

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // React.useEffect(() => {
    //     fetchFunds()
    //     //console.log("Fund: hi")
    // }, [email])

    async function fetchFunds() {
        await get(api.fund.investorList(email!), setFunds, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    }

    const tabItems = [
        {
            key: 'basic_info',
            label: 'Basic Info',
            // children: <BasicInfo nameid={fundNameID} />,
            children: <BasicInfo fund_id={fundID} nameid={fundNameID} />,
        },
        // {
        //     key: 'investor',
        //     label: 'Investor',
        //     // children: <Investor nameid={fundNameID} />,
        //     children: <Investor fund_id={fundID} nameid={fundNameID} />,
        // },
        // {
        //     key: 'holding_properties',
        //     label: 'Holding Properties',
        //     // children: <Projects nameid={fundNameID} />,
        //     children: <Projects fund_id={fundID} />,
        // },
    ];

    useEffect(() => {
        fetchFunds();
    }, [email]);

    useEffect(() => {
        //console.log("Fund useEffect", searchParams)
        // if (searchParams.get('nameid')) {
        //     if (funds.find(i => i.nameid === searchParams.get('nameid'))) {
        //         if (tabItems.find(i => i.key === searchParams.get('tabKey'))) {
        //             setActiveTabKey(searchParams.get('tabKey') || 'basic_info');
        //         } else {
        //             setActiveTabKey('basic_info');
        //         }
        //         setFundNameID(searchParams.get('nameid') || '');
        //         onFundNameIDInputValueChange(searchParams.get('nameid') || '');
        //     } else if (funds.length > 0) {
        //         messageApi.error("Please enter a valid investor name ID");
        //     }
        // }
        if (searchParams.get('fund_id')) {
            if (funds.find(i => i.nameid === searchParams.get('nameid'))) {
                if (tabItems.find(i => i.key === searchParams.get('tabKey'))) {
                    setActiveTabKey(searchParams.get('tabKey') || 'basic_info');
                } else {
                    setActiveTabKey('basic_info');
                }
                setFundNameID(searchParams.get('nameid') || '');
                onFundNameIDInputValueChange(searchParams.get('nameid') || '');
            } else if (funds.length > 0) {
                messageApi.error("Please enter a valid investor name ID");
            }
        }
    }, [searchParams, funds]);

    const onNameIDSearch = (value: number) => {
        //console.log("onNameIDSearch", value)
        if (!funds.find(x => x.fund_id === value)) {
            messageApi.error("Please enter a valid fund name ID");
            return;
        }
        // setFundNameID(value);
        setFundID(value);
    }

    return <Layout>
        {contextHolder}
        <h3>Select a fund / subfund / holding company to view</h3>
        <div className="fund-choose">
            {/* <AutoComplete
                value={fundNameIDInputValue}
                onChange={onFundNameIDInputValueChange}
                options={funds
                    .sort((a: FundData, b: FundData) => {
                        return a.fund_id < b.fund_id ? -1 : 1
                    })
                    .map((x) => ({
                        // value: x.nameid,
                        // label: `${x.name} (${x.nameid})`,
                        value: x.name,
                        label: `${x.name} (ID: ${x.fund_id})`,
                    })).filter((x) => x.label.toLowerCase().includes(
                        fundNameIDInputValue.trim().toLowerCase())
                    )}
            >
                <Input.Search
                    onSearch={onNameIDSearch}
                    size="large"
                    placeholder="Search fund name ID"
                />
            </AutoComplete> */}
            <Select
                // showSearch
                // value={fundNameIDInputValue}
                // onChange={onFundNameIDInputValueChange}
                onChange={onNameIDSearch}
                placeholder="Search fund name ID"
                style={{ width: '200px' }}
                optionFilterProp="children"
                options={funds
                    .sort((a: FundData, b: FundData) => {
                        return a.fund_id < b.fund_id ? -1 : 1
                    })
                    .map((x) => ({
                        // value: x.nameid,
                        // label: `${x.name} (${x.nameid})`,
                        value: x.fund_id,
                        label: `${x.name} (ID: ${x.fund_id})`,
                    }))}
            // filterOption={filterOption}
            >
                {/* {funds
                    .sort((a, b) => (a.fund_id < b.fund_id ? -1 : 1))
                    .map((x) => {

                    }
                    )
                } */}
            </Select>
        </div>
        <Divider />
        <div className='tabs'>
            <Tabs
                tabBarStyle={{ width: '100%' }}
                defaultActiveKey="basic_info"
                tabPosition="top"
                activeKey={activeTabKey}
                onChange={(val) => setActiveTabKey(val)}
                items={tabItems}
            />
        </div>
    </Layout>
}

export default Fund;
