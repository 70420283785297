import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation, matchPath, Navigate, } from "react-router-dom";
import { MenuProps, message } from 'antd';
import { useFiefIsAuthenticated } from '@fief/fief/react';
import { TeamOutlined, FundOutlined, BankOutlined, HomeOutlined, DashboardOutlined, SettingOutlined, MoneyCollectOutlined, FilePdfOutlined, FormOutlined, AccountBookOutlined } from '@ant-design/icons';
import { theme, Menu, Layout, Divider, Row, Col, ConfigProvider } from 'antd';
import { getItem } from './utils/common';

import { ItemType } from 'antd/es/menu/hooks/useItems';

import Home from 'pages/Home';
import Fund from 'pages/Fund';
import Investor from 'pages/Investor';
import NotFound from 'pages/NotFound';
import Property from 'pages/Property';
import Settings from 'pages/Settings';
import Register from 'pages/Register';

// fief components
import Callback from 'components/Fief/Callback';
import Navbar from 'components/Navbar';
import RequireAuth from 'components/Fief/RequireAuth';

import './App.css';
import mainLogo from 'assets/img/logo-fullsize.png';
import phone from 'assets/img/phone.png'
import emailImg from 'assets/img/email.png'
import address from "assets/img/address.png"
import www from "assets/img/www.png"
import OfferingCard from 'components/OfferingCard';
import Offerings from 'pages/Offerings';
import Project from 'pages/Project';
import { Holdings } from 'pages/Holdings/Holdings';
import Test from 'pages/Test';
import Tables from 'pages/Tables';
import { get, post } from 'utils/fetch';
import { OfferingGallery } from 'components/OfferingGallery';

import { LoginEmailContext } from 'components/Fief/Context';
import api from 'api';
import headerPerson from "./assets/img/headerPerson.png"
import avatar from "./assets/img/avatar.png"
import { CompanyInfo } from 'components/CompanyInfo';
import { isPhoneSize } from 'utils/util';
import { AppHeader } from 'components/AppHeader';
import { Logout } from 'components/Logout';
import LogoutCallback from 'components/LogoutCallback';
import { AccountCreation } from 'pages/AccountCreation/AccountCreation';
import { isAdmin } from 'utils/util';
import { RecordInsertion } from 'pages/RecordInsertion/RecordInsertion';
import { RecordManager } from 'pages/RecordManager/RecordManager';
import { PayoutSummary } from 'pages/PayoutSummary/PayoutSummary';
import { GlobalProvider } from 'GlobalContext';

const { Header, Content, Sider } = Layout;

const App: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useFiefIsAuthenticated();
    const homeMatch = matchPath(window.location.pathname, "/home");
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [uninvestedFunds, setUninvestedFunds] = useState<Record<string, any>>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [isOldUser, setIsOldUser] = useState<boolean>(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    // Add “Invest” button in Offerings cards
    let items: MenuProps['items'] = isPhoneSize() ? [
        getItem('Home', 'home', <DashboardOutlined />),
        getItem('Offerings', 'offerings', <MoneyCollectOutlined />),
        getItem('My Holdings', 'holdings', <BankOutlined />),
        getItem('Documents', 'documents', <FilePdfOutlined />),
        getItem('Settings', 'settings', <SettingOutlined />),
    ] : [
        {
            type: 'divider'
        },
        // getItem('Home', 'home', <DashboardOutlined />),
        // getItem('Tables', 'tables', <TeamOutlined />),
        getItem('Offerings', 'offerings', <MoneyCollectOutlined />),
        // getItem('Projects', 'project', <HomeOutlined />),
        // getItem('Funds', 'fund', <FundOutlined />),
        // getItem('Investor', 'investor', <TeamOutlined />),
        // getItem('Properties', 'property', <HomeOutlined />),
        getItem('My Holdings', 'holdings', <BankOutlined />),
        getItem('Documents', 'documents', <FilePdfOutlined />),
        {
            type: 'divider'
        },
        getItem('Home', 'home', <DashboardOutlined />),
        getItem('Settings', 'settings', <SettingOutlined />),
        // getItem('Test', 'test', <SettingOutlined />),
        // getItem('Plaid', 'plaid', <BankOutlined />),
    ];
    //console.log("isAdmin()", isAdmin())
    if (isAdmin()) {
        items = isPhoneSize() ? [
            getItem('Home', 'home', <DashboardOutlined />),
            getItem('Offerings', 'offerings', <MoneyCollectOutlined />),
            getItem('My Holdings', 'holdings', <BankOutlined />),
            getItem('Documents', 'documents', <FilePdfOutlined />),
            getItem('Settings', 'settings', <SettingOutlined />),
            getItem('Manage Records', 'recordManager', <FormOutlined />),
            getItem('Payout Summary', 'payoutSummary', <AccountBookOutlined />),
        ] : [
            {
                type: 'divider'
            },
            getItem('Offerings', 'offerings', <MoneyCollectOutlined />),
            getItem('My Holdings', 'holdings', <BankOutlined />),
            getItem('Documents', 'documents', <FilePdfOutlined />),
            getItem('Manage Records', 'recordManager', <FormOutlined />),
            getItem('Payout Summary', 'payoutSummary', <AccountBookOutlined />),
            {
                type: 'divider'
            },
            getItem('Home', 'home', <DashboardOutlined />),
            getItem('Settings', 'settings', <SettingOutlined />),
        ];
    }

    async function fetchInvestorBasicInfo(email: string) {
        await get(
            api.investor.getBasicInfo(email),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
    }


    useEffect(() => {
        //console.log("get basic info")
        async function callFetchInvestorBasicInfo() {
            // setLoading(true);
            await fetchInvestorBasicInfo(email!);
        }
        // setLoading(true);
        if (email !== undefined) {
            callFetchInvestorBasicInfo();
        }
    }, [email]);

    useEffect(() => {
        window.document.title = 'AlphaX RE Capital'
    }, [])

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        async function getUninvestedList(email: string) {
            await get(
                api.fund.uninvestedList(email),
                setUninvestedFunds,
                (errors: any[]) => errors.forEach(err => {
                    displayErrorMessage(err.message);
                })
            );
        }
        if (email === undefined) return
        //console.log("App.tsx, useEffect", email)
        getUninvestedList(email)
    }, [email])
    //console.log("uninvestedFunds", uninvestedFunds)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const handleMenu: MenuProps['onClick'] = (value: ItemType) => {
        let nextPath = value?.key as string || '';
        setPage(nextPath);
    }
    const setPage = (key: string) => {
        navigate(`/${key}`);
    }

    useEffect(() => {
        if (basicInfo.legal_name === "Visitor") {
            // window.location.href = "/accountCreation"
        }
        if (Object.keys(basicInfo).length !== 0 && basicInfo.legal_name !== "Visitor") {
            setIsOldUser(true)
        }
    }, [basicInfo])

    useEffect(() => {
        if (isOldUser === true) {
            //console.log("basicInfo", basicInfo)
            // window.location.href = "/home"
            //console.log("is old user", isOldUser)
        }
    }, [isOldUser])

    let menuSelectedKeys = [];
    if (matchPath("/home/*", location.pathname)) menuSelectedKeys.push('home');
    if (matchPath("/tables/*", location.pathname)) menuSelectedKeys.push('tables');
    if (matchPath("/offerings/*", location.pathname)) menuSelectedKeys.push('offerings');
    if (matchPath("/holdings/*", location.pathname)) menuSelectedKeys.push('holdings');
    if (matchPath("/documents/*", location.pathname)) menuSelectedKeys.push('documents');
    if (matchPath("/project/*", location.pathname)) menuSelectedKeys.push('project');
    if (matchPath("/fund/*", location.pathname)) menuSelectedKeys.push('fund');
    if (matchPath("/investor/*", location.pathname)) menuSelectedKeys.push('investor');
    if (matchPath("/property/*", location.pathname)) menuSelectedKeys.push('property');
    if (matchPath("/settings/*", location.pathname)) menuSelectedKeys.push('settings');
    if (matchPath("/test/*", location.pathname)) menuSelectedKeys.push('test');
    if (matchPath("/plaid/*", location.pathname)) menuSelectedKeys.push('plaid');
    if (matchPath("/recordManager/*", location.pathname)) menuSelectedKeys.push('recordManager');
    if (matchPath("/payoutSummary/*", location.pathname)) menuSelectedKeys.push('payoutSummary');
    //console.log("basicInfo.legal_name", basicInfo.legal_name)
    const routes = (<Content style={{
    }}>

        <div style={isPhoneSize() ? {
            // display: 'flex',
            width: '100%',
            padding: 10,
            // minHeight: 280,
            justifyContent: 'center',
            alignItems: 'center'
        } : {
            // display: 'flex',
            width: '100%',
            padding: 10,
            // minHeight: 280,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Routes>
                <Route index element={
                    // <LoginEmailContext.Provider value={email}>
                    <>
                        {/* <RequireAuth setEmail={setEmail}> */}
                        <Navigate to="/home" />
                        {/* </RequireAuth> */}
                    </>
                    // {/* </LoginEmailContext.Provider> */}
                } />
                <Route path="/callback" element={<Callback />} />
                <Route path="/notfound" element={<NotFound />} />
                <Route path="/home" element={
                    <RequireAuth setEmail={setEmail}>
                        <Home email={email} setEmail={setEmail} basicInfo={basicInfo} setBasicInfo={setBasicInfo} isOldUser={isOldUser} />
                    </RequireAuth>
                } />
                <Route path="/offerings" element={
                    <RequireAuth setEmail={setEmail}>
                        <Offerings email={email} basicInfo={basicInfo} setEmail={setEmail} />
                    </RequireAuth>
                } />
                <Route path="/project" element={
                    <RequireAuth setEmail={setEmail}>
                        <Project />
                    </RequireAuth>
                } />
                <Route path="/holdings" element={
                    <RequireAuth setEmail={setEmail}>
                        <Holdings email={email} basicInfo={basicInfo} setEmail={setEmail} />
                    </RequireAuth>
                } />
                <Route path="/recordManager" element={
                    <RequireAuth setEmail={setEmail}>
                        <RecordManager email={email} basicInfo={basicInfo} setEmail={setEmail} />
                    </RequireAuth>
                } />
                <Route path="/payoutSummary" element={
                    <RequireAuth setEmail={setEmail}>
                        <PayoutSummary email={email} basicInfo={basicInfo} setEmail={setEmail} />
                    </RequireAuth>
                } />
                <Route path="/fund" element={
                    <RequireAuth setEmail={setEmail}>
                        <Fund email={email} setEmail={setEmail} />
                    </RequireAuth>
                } />
                <Route path="/investor" element={
                    <RequireAuth setEmail={setEmail}>
                        <Investor />
                    </RequireAuth>
                } />
                <Route path="/property" element={
                    <RequireAuth setEmail={setEmail}>
                        <Property />
                    </RequireAuth>
                } />
                <Route path="/tables" element={
                    <RequireAuth setEmail={setEmail}>
                        <Tables />
                    </RequireAuth>
                } />
                <Route path="/settings" element={<>
                    {/* <RequireAuth setEmail={setEmail}> */}
                    <Settings />
                    {/* </RequireAuth> */}
                </>
                } />
                <Route path="/test" element={
                    <RequireAuth setEmail={setEmail}>
                        <Test />
                    </RequireAuth>
                } />
                <Route path="/accountCreation" element={
                    <RequireAuth setEmail={setEmail}>
                        <AccountCreation basicInfo={basicInfo} setBasicInfo={setBasicInfo} />
                    </RequireAuth>
                } />
                <Route path="/register" element={<Register />} />
                <Route path="/logout" element={<>You have been logged out.</>} />
                {/* <Route path="/login" element={<></>} /> */}
                {/* <Route path="/plaid" element={<RequireAuth><Plaid /></RequireAuth>} /> */}
                <Route path="*" element={<NotFound />} />
                <Route path="/logout-callback" element={<LogoutCallback />} />
            </Routes>
        </div>
    </Content>)

    const sideBar = (<>{isAuthenticated && homeMatch && isOldUser &&
        <div style={isPhoneSize() ? {
            width: "100%", background: 'transparent', padding: 12, overflowY: 'hidden', overflowX: "hidden", scrollbarWidth: "none"
        } : {
            width: '290px', background: 'transparent', padding: 12, overflowY: 'scroll', overflowX: "hidden", height: "100vh", scrollbarWidth: "none"
        }}>
            <h1 style={{ margin: "0" }}>Offerings</h1>
            <Divider style={{ margin: "10px" }} />
            <h3 style={{ margin: "0", marginBottom: "10px" }}>Active Offerings</h3>
            <Row gutter={[12, 16]}>
                <div style={{ display: "flex", width: "100%" }}>
                    {/* <div> */}
                    <OfferingGallery uninvestedFunds={uninvestedFunds.sort((a: any, b: any) => {
                        if (a.name < b.name) return -1
                        return 1
                    })} />
                    {/* </div> */}
                </div>
            </Row>
            <Divider style={{ margin: "0", marginTop: "5px" }} />
            {/* <h3>Past Offerings</h3> */}
            <Row gutter={[12, 16]}>

            </Row>

            <CompanyInfo />

        </div>
    }</>)

    // function isOldUser() {
    //     return Object.keys(basicInfo).length !== 0 && basicInfo.legal_name !== "Visitor"
    // }

    return (<GlobalProvider>
        <div style={{ background: "linear-gradient(rgb(255 255 255), rgb(230 234 255))" }}>

            <LoginEmailContext.Provider value={email}>
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 12,
                        },
                    }}
                    componentSize='small'
                >
                    {Object.keys(basicInfo).length === 0 && basicInfo.legal_name !== "Visitor"}
                    <Layout style={{
                        height: '100%',
                        background: "linear-gradient(rgb(255 255 255), rgb(230 234 255))"
                    }}>
                        {isPhoneSize() && isOldUser && <AppHeader basicInfo={basicInfo} setEmail={setEmail} />}
                        <Layout style={isPhoneSize() ? {
                            background: "transparent",
                            flexDirection: "column",
                            height: "calc(100vh - 0px)"
                        } : {
                            background: "transparent",
                            flexDirection: "row"
                        }}>
                            {isAuthenticated && isOldUser &&
                                <div style={isPhoneSize() ? {} : { marginLeft: "30px" }}>
                                    <div style={isPhoneSize() ? {
                                        display: "flex",
                                        flexDirection: "row"
                                    } : { color: "rgb(17 32 86)", margin: "10px", marginTop: "50px", fontSize: "17px" }}>
                                        <div style={isPhoneSize() ? { width: "50%", alignSelf: 'center', paddingLeft: "60px", paddingTop: "17px" } : {}}>
                                            AlphaX{" "}
                                            <br ></br>
                                            Investor Portal
                                        </div>
                                        {basicInfo !== null && basicInfo.legal_name && isPhoneSize() && <div style={{ display: "flex", alignItems: "center", position: 'relative', top: "5px" }}>
                                            <div>
                                                <img src={avatar} style={{ width: "40px", margin: "10px", marginBottom: "0", marginLeft: "0" }}></img>
                                            </div>
                                            <div style={{ color: "rgb(17 32 86)", fontSize: "14px", marginTop: "10px" }}>
                                                {basicInfo !== null && basicInfo.legal_name}
                                            </div>
                                        </div>}
                                    </div>

                                    <Sider
                                        // collapsible={true}
                                        width={170}
                                        collapsedWidth={0}
                                        style={isPhoneSize() ? {
                                            background: "transparent",
                                            display: "flex"
                                        } : {
                                            background: "transparent"
                                        }}
                                        zeroWidthTriggerStyle={{
                                            top: 10,
                                        }}
                                    >
                                        <div style={isPhoneSize() ? {
                                            display: "flex",
                                            width: window.innerWidth,
                                            overflowX: 'scroll',
                                            overflowY: "hidden",
                                            scrollbarWidth: "none"
                                        } : {}}>
                                            <div style={isPhoneSize() ? {
                                                width: "100%",
                                                height: "70px",
                                                background: "white",
                                                borderRadius: "10px 10px 0 0",
                                                display: "none",
                                                alignItems: 'center',
                                                placeContent: "center"
                                            } : {
                                                width: "100%",
                                                height: "70px",
                                                background: "white",
                                                borderRadius: "10px 10px 0 0",
                                                display: "flex",
                                                alignItems: 'center',
                                                placeContent: "center"
                                            }}>
                                                {basicInfo !== null && basicInfo.legal_name && <div style={{ display: "flex", alignItems: "center", position: 'relative', top: "5px" }}>
                                                    <div>
                                                        <img src={avatar} style={{ width: "40px", margin: "10px", marginBottom: "0", marginLeft: "0" }}></img>
                                                    </div>
                                                    <div style={{ color: "rgb(17 32 86)", fontSize: "14px", marginTop: "10px" }}>
                                                        {basicInfo !== null && basicInfo.legal_name}
                                                    </div>
                                                </div>}
                                            </div>
                                            <Menu
                                                style={isPhoneSize() ? {
                                                    display: "flex",
                                                    border: "none",
                                                    background: "transparent"
                                                } : {
                                                    height: '100%',
                                                    borderRadius: "0 0 10px 10px",
                                                    border: "none"
                                                }}
                                                onClick={handleMenu}
                                                selectedKeys={menuSelectedKeys}
                                                // defaultSelectedKeys={['home']}
                                                mode={isPhoneSize() ? "horizontal" : "inline"}
                                                items={items}
                                                disabledOverflow={true}
                                            />
                                        </div>
                                    </Sider>
                                </div>
                            }
                            {!isPhoneSize() && <>{routes}{sideBar}</>}
                            {isPhoneSize() && <div style={{
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                scrollbarWidth: "none",
                                height: "calc(100vh - 200px)"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div>
                                        {routes}
                                    </div>
                                    <div>
                                        {sideBar}
                                    </div>
                                    {/* <div style={{ height: "200px" }}></div> */}
                                </div>
                            </div>}
                        </Layout>
                    </Layout>
                </ConfigProvider>
            </LoginEmailContext.Provider>
        </div>
    </GlobalProvider>
    );
};

export default App;
