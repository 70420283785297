import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import _ from 'lodash';
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Select } from 'antd';
import api from 'api';
import { get, post } from 'utils/fetch';
import { NameItem } from 'types/investor';
import OfferingTile from './OfferingTile';
import { useLocation } from 'react-router-dom';
import { OfferingDetailed } from './OfferingDetailed';
import { AppHeader } from 'components/AppHeader';
import { CompanyInfo } from 'components/CompanyInfo';
import { isPhoneSize } from 'utils/util';

const { Text } = Typography;

interface OfferingProps {
    email: string | undefined;
    basicInfo: any;
    setEmail: any
}

const Offerings: React.FC<OfferingProps> = ({ email, basicInfo, setEmail }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    //console.log("Offering id:", id)

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(true);
    const [funds, setFunds] = useState<any[]>([]);
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const [fundTypeToView, setFundTypeToView] = useState<string>("Both")

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFunds(email: string) {
        await get(api.fund.uninvestedList(email), setFunds, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    };

    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach((e, i) => {
                updateObj[e] = data[i];
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }
        ));
        setLoading(false);
    }


    async function fetchData(email: string) {
        setLoading(true);
        await fetchFunds(email);
        //console.log(funds)
        setLoading(false);
    }


    useEffect(() => {
        if (email === undefined) return
        fetchData(email);
    }, [email]);


    //console.log(funds)
    let content = null;
    if (id !== null) return <>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {!isPhoneSize() && <AppHeader basicInfo={basicInfo} setEmail={setEmail} />}
                <OfferingDetailed id={id} isHolding={false} />
            </div>
            {!isPhoneSize() &&
                <div style={{ width: "290px" }}>
                    <CompanyInfo action={"Invest"} />
                </div>
            }
        </div>
    </>

    if (loading) content = <Spin style={{ height: "100vh", marginTop: "30px" }} />;
    else {

        content = <div style={isPhoneSize() ? {} : { overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: "none", height: "calc(100vh - 144px)", width: '100%' }}>

            <Layout style={{ background: "transparent" }}>
                <div style={{ display: "flex" }}>
                    <h1 style={{ width: '100%', paddingTop: "5px" }}>Offerings</h1>
                    {/* <h2>Debt Offerings</h2> */}
                    <Select
                        onChange={(e) => setFundTypeToView(e)}
                        placeholder="Filter Fund Type"
                        style={{ width: '200px' }}
                        optionFilterProp="children"
                        options={
                            [{
                                value: "Both",
                                label: "Both",
                            }, {
                                value: "Debt",
                                label: "Debt",
                            }, {
                                value: "Equity",
                                label: "Equity",
                            }]
                        }
                    >
                    </Select>
                </div>
                <Row gutter={[24, 24]} style={isPhoneSize() ? { justifyContent: "center" } : {}}>
                    {
                        funds
                            .filter((fund) => {
                                if (fundTypeToView === "Both") {
                                    return true
                                } else {
                                    return fund.type.toLowerCase().includes(fundTypeToView.toLowerCase())
                                }
                            })
                            .sort((a, b) => {
                                if (a.name < b.name) return -1
                                return 1
                            })
                            .map(f =>
                                <Col>
                                    <OfferingTile
                                        title={f.name}
                                        type={f.type}
                                        description={f.description}
                                        link={`/offerings?id=${f.id}`}
                                        imgSrc={f.img_src ? f.img_src : 'https://static.wixstatic.com/media/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png/v1/fill/w_948,h_1226,al_t,q_90,usm_0.66_1.00_0.01,enc_auto/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png'}
                                    />
                                </Col>
                            )
                    }
                </Row>

            </Layout>
            <div style={{ height: "50px", width: "100%", display: "flex" }}></div>
        </div>
    }
    return <div style={isPhoneSize() ? {
        display: 'flex', flexDirection: "column",
        // overflowX: "hidden", 
        scrollbarWidth: "none", height: "calc(100vh - 154px)", paddingBottom: "50px"
    } : {
        display: 'flex', flexDirection: "row"
    }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {!isPhoneSize() && <AppHeader basicInfo={basicInfo} setEmail={setEmail} />}
            {id === null ? <>
                {contextHolder}{content}
            </> : <></>}

        </div>
        <div style={isPhoneSize() ? {
            width: '380px',
            textAlign: "left",
            background: 'transparent',
            padding: 12,
        } : {
            width: '380px',
            textAlign: "left",
            background: 'transparent',
            padding: 12,
            overflowY: 'scroll',
            overflowX: "hidden",
            height: "100vh",
            scrollbarWidth: "none",
            // height: 'calc(100vh - 144px)'
        }}>
            {/* <h1 style={{ margin: "0" }}>Offerings</h1> */}
            {/* <Divider style={{ margin: "10px" }} /> */}
            {/* <h3 style={{ margin: "0", marginBottom: "10px" }}>Active Offerings</h3> */}
            {/* <Row gutter={[12, 16]}>
                    <OfferingGallery uninvestedFunds={[]} />
                </Row> */}
            {/* <Divider style={{ margin: "0", marginTop: "5px" }} /> */}
            {/* <h3>Past Offerings</h3> */}
            <Row gutter={[12, 16]}>

            </Row>

            <CompanyInfo />
        </div>
    </div>
}

export default Offerings;
