import React, { useEffect, useState } from 'react';
import { DebtRecordItem, NameItem, FilterItem, InvestmentTypeEnum, DebtRecord } from 'types/investor';
import { TabComponentProps } from 'types/common';
import { AlignType } from 'rc-table/lib/interface';
import { Spin, Table, message, Typography, Divider } from 'antd';
import _ from 'lodash';
import api from 'api';
import { get, post } from 'utils/fetch';
import { USDollar } from 'utils/format';

const { Text } = Typography;

const DebtPayouts: React.FC<TabComponentProps> = ({ nameid }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [debtRecord, setDebtRecord] = useState<DebtRecord[]>([]);
    const [debtRecordItems, setDebtRecordItems] = useState<DebtRecordItem[]>([]);
    const [debtsReceived, setDebtReceived] = useState<number>(0);
    const [nameidMapping, setNameIDMapping] = useState<Record<string, NameItem>>({});
    const [debtFilter, setDebtFilter] = useState<FilterItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const debtRecordColumns = [{
        title: 'From Fund',
        dataIndex: 'from_nameid',
        render: (value: string, record: DebtRecordItem, index: number) => {
            const { from_nameid } = record
            if (from_nameid && from_nameid in nameidMapping) return `${nameidMapping[from_nameid].name} (${from_nameid})`;
            else return from_nameid;
        },
        filters: debtFilter,
        onFilter: (value: any, record: DebtRecordItem) => {
            return record.from_nameid.indexOf(value) === 0;
        }
    }, {
        title: 'Payout Date',
        dataIndex: 'transaction_date',
        render: (val: string) => new Date(val).toISOString().split('T')[0],
        sorter: (a: DebtRecordItem, b: DebtRecordItem) => {
            return new Date(a.transaction_date).valueOf() - new Date(b.transaction_date).valueOf();
        },
        defaultSortOrder: 'descend' as const,
    }, {
        title: 'Category',
        dataIndex: 'category',
    }, {
        title: 'Receivable Amount ($)',
        dataIndex: 'expense',
        align: 'right' as AlignType,
        render: (val: number) => val < 0 ? USDollar.format(-val) : USDollar.format(val),
        sorter: (a: DebtRecordItem, b: DebtRecordItem) => {
            let aa = a.expense || 0;
            let bb = b.expense || 0;
            return Math.abs(aa) - Math.abs(bb);
        },
    }];
    
    
    async function fetchDebtRecord(nameid: string) {
        setLoading(true);
        await get(api.investor.getDebtRecord(nameid), (data) => {
            setDebtRecord(data);
            let receivedSum = 0;
            let filterUniqueNameIds = new Set<string>();
            let tableItems = [];
            for (const record of data as DebtRecord[]) {
                let tableItem: DebtRecordItem = {
                    from_nameid: '',
                    investor_nameid: record.investor_nameid,
                    transaction_date: record.transaction_date,
                    expense: record.expense,
                    income: record.income,
                    balance: record.balance,
                    category: record.category,
                };
                let nameid = ''
                if (record.fund_nameid) nameid = record.fund_nameid
                else if (record.subfund_nameid) nameid = record.subfund_nameid
                else if (record.holding_company_nameid) nameid = record.holding_company_nameid
                tableItem.from_nameid = nameid
                tableItems.push(tableItem);
                filterUniqueNameIds.add(nameid);
    
                receivedSum += record.expense;
            }
            setDebtRecordItems(tableItems);
            if (receivedSum < 0) receivedSum = -receivedSum;
            setDebtFilter(
                Array.from(filterUniqueNameIds).map(
                    (nameid: string) => {
                        let text = nameid;
                        if (nameid in nameidMapping) text = `${nameidMapping[nameid].name} (${nameid})`
                        return {
                            text,
                            value: nameid,
                        }
                    }
                )
            );
            setDebtReceived(receivedSum);
            fetchNameIDMapping(Array.from(filterUniqueNameIds));
        }, (errors) => {
            for (const err of errors) {
                displayErrorMessage(err.message);
            }
        })
    }


    async function fetchNameIDMapping(nameidList: string[]) {
        await post(api.misc.getNameIDMapping, {
            payload: nameidList
        }, (data) => {
            if (data.length !== nameidList.length) return
            const updateObj: Record<string, NameItem> = {}
            nameidList.forEach(function (e, i) {
                updateObj[e] = data[i]
            });
            setNameIDMapping({
                ...nameidMapping,
                ...updateObj,
            })
        }, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchNameIDMapping([nameid]);
            fetchDebtRecord(nameid);
        }
    }, [nameid]);

    if (loading) return <Spin />
    else if (!nameid) return <span>Please select a valid user</span>;

    return <>
        {contextHolder}
        <h3>Debt Interest Payout Record</h3>
        <Table
            bordered
            dataSource={debtRecordItems}
            columns={debtRecordColumns}
            summary={(rowData: readonly DebtRecordItem[]) => {
                let total = 0;

                rowData.forEach(({ expense }) => {
                    total += expense;
                });

                if (total < 0) total = -total;

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Total Interest Received</Table.Summary.Cell>
                            <Table.Summary.Cell align='right' index={1} colSpan={5}>
                                <Text type="success">{USDollar.format(total)}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    </>
}

export default DebtPayouts;