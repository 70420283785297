import React, { useEffect, useState } from 'react';
import { TabComponentProps } from 'types/common';
import _ from 'lodash';
import { Col, Descriptions, DescriptionsProps, Divider, Grid, Row, Spin, Table, Typography, message } from 'antd';
import api from 'api';
import { get } from 'utils/fetch';
import { ActualRaisedKeyNameMap, BasicInfoKeyNameMap, TargetOfferingsKeyNameMap as TargetOfferingKeyNameMap } from './const';
import { USDollar } from 'utils/format';
import { Progress } from 'antd';

const { Text } = Typography;

type DataSourceType = {
    offering_name: string,
    offering_amount: number,
    actual_raised_name: string,
    actual_raised_amount: number,
    key: string
}

type BarDataItem = {
    labelName: string,
    value: number,
    fullValue: number,
}

type FinancialBarProps = {
    data: BarDataItem[]
}

const RaisedProgress: React.FC<FinancialBarProps> = ({ data }) => {
    let displayData = data.filter(
        (d: BarDataItem) => d.labelName && d.value > 0 && d.fullValue > 0
    );
    return <div>
        {
            displayData.map((data: BarDataItem) => (
                <Row>
                    <Col span={24}>
                        {data.labelName}: {USDollar.format(data.value)} / {USDollar.format(data.fullValue)}
                        <Progress percent={data.value / data.fullValue * 100} />
                    </Col>
                </Row>
            ))
        }
    </div>;
}

const BasicInfo: React.FC<TabComponentProps> = ({ fund_id }) => {
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    async function fetchFundBasicInfo(fund_id: number) {
        setLoading(true);
        await get(
            api.fund.getBasicInfo(fund_id),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (fund_id) {
            fetchFundBasicInfo(fund_id);
        }
    }, [fund_id]);

    let typeName = '';
    // if (fund_id.toLowerCase().startsWith('f')) typeName = 'Fund';
    // if (fund_id.toLowerCase().startsWith('sf')) typeName = 'Subfund';
    // if (fund_id.toLowerCase().startsWith('h')) typeName = 'Holding Company';

    let basicDescriptionItems: DescriptionsProps['items'] = [{
        key: 'fund_type',
        label: 'Fund Type',
        children: typeName,
    }];
    Object.keys(BasicInfoKeyNameMap).forEach((key: string) => {
        if (key in basicInfo) {
            basicDescriptionItems?.push({
                key,
                label: BasicInfoKeyNameMap[key],
                children: basicInfo[key] || '-',
            });
        }
    });

    let basicSection = null;
    let descriptionSection = null;
    let financialSection = null;
    if (loading) {
        basicSection = <Spin />;
    }
    else if (!fund_id) basicSection = <span>Please enter a fund id from selection</span>;
    else if (fund_id && basicInfo?.name) basicSection = (
        <>
            <h3>Basics</h3>
            <div className='basic-info'>
                {basicSection}
            </div>
            <Descriptions bordered items={basicDescriptionItems} />
        </>
    );

    if (fund_id && basicInfo?.description) {
        descriptionSection = <>
            <h3>Fund Description</h3>
            <div className="description">
                {basicInfo?.description}
            </div>
        </>;
    }

    if (fund_id && basicInfo?.target_offerings_equity) {
        let dataSource: DataSourceType[] = [];
        let barData: BarDataItem[] = [];
        const targetOfferingKeys = Object.keys(TargetOfferingKeyNameMap);
        const actualRaisedKeys = Object.keys(ActualRaisedKeyNameMap);

        if (targetOfferingKeys?.length === actualRaisedKeys?.length) {
            targetOfferingKeys.forEach((tkey, i) => {
                const akey = actualRaisedKeys[i];
                dataSource.push({
                    offering_name: TargetOfferingKeyNameMap[tkey],
                    offering_amount: basicInfo[tkey],
                    actual_raised_name: ActualRaisedKeyNameMap[akey],
                    actual_raised_amount: basicInfo[akey],
                    key: tkey,
                });
                barData.push({
                    labelName: ActualRaisedKeyNameMap[akey],
                    value: basicInfo[akey],
                    fullValue: basicInfo[tkey],
                });
            });
        }


        financialSection = <>
            <h3>Financial Summary</h3>
            <div className="financial-summary">
                <Table bordered pagination={false}
                    columns={[{
                        title: 'Offerings',
                        dataIndex: 'offering_name',
                    }, {
                        title: 'Amount ($)',
                        dataIndex: 'offering_amount',
                        align: 'right' as const,
                        render: (x) => USDollar.format(x),
                    }, {
                        title: 'Actual Raised',
                        dataIndex: 'actual_raised_name',
                    }, {
                        title: 'Amount ($)',
                        dataIndex: 'actual_raised_amount',
                        align: 'right' as const,
                        render: (x) => USDollar.format(x),
                    }]}
                    dataSource={dataSource}
                    summary={(rowData: readonly DataSourceType[]) => {
                        let totalOffering = 0;
                        let totalRaised = 0;

                        rowData.forEach(({ offering_amount, actual_raised_amount }) => {
                            totalOffering += offering_amount;
                            totalRaised += actual_raised_amount;
                        });

                        if (totalOffering < 0) totalOffering = -totalOffering;
                        if (totalRaised < 0) totalRaised = -totalRaised;

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Target Offerings Total</Table.Summary.Cell>
                                    <Table.Summary.Cell align='right' index={1}>
                                        <Text >{USDollar.format(totalOffering)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>Actual Raised Total</Table.Summary.Cell>
                                    <Table.Summary.Cell align='right' index={1}>
                                        <Text >{USDollar.format(totalRaised)}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }} />
                <Divider />
                <RaisedProgress data={barData} />
            </div>
        </>;
    }

    return <>
        {contextHolder}
        {basicSection}
        {descriptionSection}
        {financialSection}
    </>;
}

export default BasicInfo;