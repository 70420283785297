
export type InvestorData = {
    id: string
    nameid: string
    legal_name: string
}

export const enum InvestmentTypeEnum {
    Debt = 'Debt',
    Equity = 'Equity',
}

export type InvestmentRecord = {
    id: number
    investor_nameid: string
    legal_name?: string
    fund_nameid?: string
    subfund_nameid?: string
    to_nameid?: string
    name?: string
    fund_name?: string
    holding_company_nameid?: string
    amount: number
    investment_amount: number
    status: string
    transaction_date: string
    share_of_fund?: number
    annual_interest_rate?: number
    type: InvestmentTypeEnum | null
    address?: string | undefined
}

export type InvestmentItem = {
    address?: string | undefined
    investor_nameid: string
    legal_name: string
    to_nameid: string
    investment_amount: number
    amount: number
    type: InvestmentTypeEnum | null
}

export type DebtRecord = {
    investor_nameid: string,
    fund_nameid?: string
    subfund_nameid?: string
    holding_company_nameid?: string
    transaction_date: string
    expense: number
    income: number
    balance: number
    category: string
}

export type DebtRecordItem = {
    investor_nameid: string
    from_nameid: string
    transaction_date: string
    expense: number
    income: number
    balance: number
    category: string
}

export type NameItem = {
    name: string
}

export type FilterItem = {
    text: string,
    value: any
}

export type PropertyOwnershipData = {
    address: string,
    state?: string,
    zip?: string
    ownership: string | number,
    title_holder?: string,
}