import backArrow from "../../assets/img/backArrow.png"
import { USDollar } from "utils/format"
import { investmentStructToName } from "utils/util"
import { useState, useEffect } from "react"
import api from "api"
import { message, Spin, Select, Space, Calendar, theme } from "antd"
import { get } from "utils/fetch"
import { investorStructToDisplayedName, fundEntityToName, getTodayDateString, getTomorrowDateString } from "utils/util"
import { DeleteConfirmation } from "components/DeleteConfirmation"

export function InvestmentInsertion(props) {
    const { investment, setInvestmentId, funds } = props

    const [investors, setInvestors] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [investorIdSelected, setInvestorIdSelected] = useState(-1)
    const [accountIdSelected, setAccountIdSelected] = useState(-1)
    const [fundIdSelected, setFundIdSelected] = useState(-1)
    const [amount, setAmount] = useState(0)
    const [method, setMethod] = useState("Cashout")
    const [type, setType] = useState("")
    const [frequency, setFrequency] = useState(0)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [interest, setInterest] = useState(0)
    const [account, setAccount] = useState("")
    const [showDeletionBox, setShowDeletionBox] = useState(false)

    // all accounts
    const [accounts, setAccounts] = useState([])
    // previously taken out
    const [principalTakenOut, setPrincipalTakenOut] = useState([])
    // will be taken out in current session
    const [principalTakeOut, setPrincipalTakeOut] = useState([])

    const { token } = theme.useToken();
    const wrapperStyle = {
        width: 300,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchInvestors() {
        await get(api.manager.investors, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchPrincipalTakeout() {
        await get(api.manager.investmentPrincipalTakeOut(investment.investment_id), setPrincipalTakenOut, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }
    // console.log(principalTakenOut)
    // init, get investors and funds
    useEffect(() => {
        if (investment !== null) {
            // for existing investment, don't display all investors and funds
            // but need to retrive principal takeout information
            fetchPrincipalTakeout()
            return
        }
        // fetchInvestors()
        fetchAccounts()
    }, [])

    function mapNameToId(entities, getKey) {
        var mapRes = {}
        for (const entity of entities) {
            mapRes[getKey(entity)] = entity.id
        }
        return mapRes
    }

    const investorNameToId = mapNameToId(investors, investorStructToDisplayedName)
    const fundNameToId = mapNameToId(funds, fundEntityToName)
    var accountName2id = {}
    for (const account of accounts) {
        accountName2id[account.account_name] = account.id
    }

    //console.log(investment)
    // ----------------- investor selecion -------------------
    const handleChangeInvestor = (investor) => {
        //console.log(`investor ${investor} with id ${investorNameToId[investor]} selected`)
        setInvestorIdSelected(investorNameToId[investor])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };

    const investorSelectionOptions = investors
        .sort((a, b) => {
            if (investorStructToDisplayedName(a) < investorStructToDisplayedName(b)) {
                return -1
            }
            return 1
        })
        .map(investor => {
            return {
                label: investorStructToDisplayedName(investor),
                value: investorStructToDisplayedName(investor),
                desc: investorStructToDisplayedName(investor),
            }
        })

    // ----------------- account selecion -------------------
    const handleChangeAccount = (account) => {
        //console.log(`investor ${investor} with id ${investorNameToId[investor]} selected`)
        setAccountIdSelected(accountName2id[account])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };

    const accountSelectionOptions = accounts
        .sort((a, b) => {
            if (a.account_name < b.account_name) {
                return -1
            }
            return 1
        })
        .map(account => {
            return {
                label: account.account_name,
                value: account.account_name,
                desc: account.account_name,
            }
        })

    // ----------------- fund selecion -------------------
    const handleChangeFund = (fund) => {
        //console.log(`fund ${fund} with id ${fundNameToId[fund]} selected`)
        setFundIdSelected(fundNameToId[fund])
        // companies: companies names
        // setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        // setDirty(true)
    };

    const fundSelectionOptions = funds
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            return 1
        })
        .map(fund => {
            return {
                label: fund.name,
                value: fund.name,
                desc: fund.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        // setFundIdSelected(fundNameToId[fund])
        const typeRes = type.sort((a, b) => a < b ? -1 : 1).join("/")
        setType(typeRes)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]


    // ----------------- method selecion -------------------

    const handleChangeMethod = (method) => {
        setMethod(method)
        //console.log(method)
    };

    const methodSelectionOptions = [{
        label: "Reinvest",
        value: "Reinvest",
        desc: "Reinvest",
    }, {
        label: "Cashout",
        value: "Cashout",
        desc: "Cashout",
    }]
    //  ----------------- start date selection -------------------
    const onStartDateChange = (value, mode) => {
        //console.log(value.format('YYYY-MM-DD'), mode);
    };

    // ----------------- frequency selecion -------------------

    const handleChangeFrequency = (frequency) => {
        setFrequency(frequency)
    };

    const frequencySelectionOptions = [{
        label: "Quarterly payout",
        value: "Quarterly payout",
        desc: "Quarterly payout",
    }, {
        label: "Monthly payout",
        value: "Monthly payout",
        desc: "Monthly payout",
    }]

    function canSubmit() {
        var invalidFields = []
        if (investment === null) {
            // if (investorIdSelected < 0) {
            //     invalidFields.push("Please select investor.")
            // }

            if (accountIdSelected < 0) {
                invalidFields.push("Please select account.")
            }

            if (fundIdSelected < 0) {
                invalidFields.push("Please select fund.")
            }

            if (method === "") {
                invalidFields.push("Please select method.")
            }

            if (amount === 0) {
                invalidFields.push("Please enter amount.")
            }

            if (startDate === "") {
                invalidFields.push("Please select start date.")
            }

            if (endDate === "") {
                invalidFields.push("Please select end date.")
            }

            if (interest === 0) {
                invalidFields.push("Please enter annual interest rate.")
            }

            if (frequency === 0) {
                invalidFields.push("Please enter payout frequency.")
            }
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    //console.log("amount", amount)
    async function saveInvestment() {
        // if (investorIdSelected < 0 || fundIdSelected < 0) {
        //     return
        // }
        //console.log(`Investor id: ${investorIdSelected}`)
        //console.log(`Fund id: ${fundIdSelected}`)
        //console.log(`Method: ${method}`)
        //console.log(`Amount: ${amount}`)
        //console.log(`Start date: ${startDate}`)
        //console.log(`End date: ${endDate}`)
        //console.log(`Annual interest: ${interest}`)
        //console.log(`Payout frequency: ${frequency}`)

        if (canSubmit() === false) {
            return
        }


        if (investment === null) {
            const body = JSON.stringify({
                // investorIdSelected,
                accountIdSelected,
                fundIdSelected,
                method,
                "amount": parseInt(amount),
                startDate,
                endDate,
                interest,
                frequency,
                account,
            })

            const options = {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json"
                },
                body
            }
            await fetch(api.manager.investments, options); // new record
        } else {
            // combine previous taken out and newly taken out principal
            const principalTakeOutMap = [...principalTakeOut,
            ...principalTakenOut
                .map((principalTakenOut) => [principalTakenOut.timestamp, principalTakenOut.amount])]
                .filter((principalTakeOutEntry, idx) => {
                    return principalTakeOutEntry[1] > 0
                })
                .reduce((acc, [date, value]) => {
                    if (acc[date]) {
                        acc[date] += value
                    } else {
                        acc[date] = value
                    }
                    return acc
                }, {})
            // it is ok to have duplicate date entry in transaction database since we aggregate principal taken out on each day prior to calculation
            const newPrincipalTakeOut = principalTakeOut
                .filter((principalTakeOutEntry, idx) => {
                    return principalTakeOutEntry[1] > 0
                })
                .sort((a, b) => {
                    if (a[0] < b[0]) {
                        return 1
                    }
                    return -1
                })

            // console.log(newPrincipalTakeOut)
            // payload['startDate'],
            // payload['endDate'],
            // payload['frequency'],
            // payload['method'],
            // payload['interest'],
            // {},
            // payload['amount'],
            const body = JSON.stringify({
                id: investment.investment_id,
                startDate: investment.funding_receipt_date.slice(0, 10),
                endDate: investment.actual_redemption_date.slice(0, 10),
                frequency: investment.payout_frequency,
                method: investment.method,
                interest: investment.interest,
                amount: investment.amount,
                // overall principal taken out aggregated by date (map)
                principalTakenOut: principalTakeOutMap,
                // new principal taken out (list)
                newPrincipalTakeOut
            })

            const options = {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json"
                },
                body
            }
            await fetch(api.manager.investment(investment.investment_id), options); // old record
        }
        setInvestmentId(0)
    }

    function addPrincipalTakeOutEntry() {
        setPrincipalTakeOut([...principalTakeOut, [getTomorrowDateString(), 0]])
    }

    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.investment(investment.investment_id), options);
        const data = await result.json()
        setInvestmentId(0)
    }


    function deleteInvestment() {
        setShowDeletionBox(true)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading &&
            <div>

                {investment !== null && showDeletionBox &&
                    <DeleteConfirmation
                        title={`Investment: ${investment.account_name} - ${investment.fund_name}`}
                        setShowDeletionBox={setShowDeletionBox}
                        deletionCallback={deletionCallback}
                    />
                }

                <div style={{ margin: "20px" }}>
                    <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                        src={backArrow}
                        onClick={() => setInvestmentId(0)}
                    ></img>
                </div>

                {creationErrorMessages.map((message, idx) => {
                    return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
                })}

                {/* <div style={entryStyle}>
                    Investor{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select investor"
                                defaultValue={[]}
                                onChange={handleChangeInvestor}
                                options={investorSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.legal_name}
                    </span>}
                </div> */}

                <div style={entryStyle}>
                    Account{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select investor"
                                defaultValue={[]}
                                onChange={handleChangeAccount}
                                options={accountSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.account_name}
                    </span>}
                </div>

                {/* <div style={entryStyle}>
                    Account:
                    {investment === null &&
                        <input style={{ ...inputStyle, ...{ width: "150px" } }}
                            onInput={e => setAccount(e.target.value)}>
                        </input>
                    }
                    {investment !== null && <span>
                        {investment.account !== null && ' ' + investment.account}
                    </span>}
                </div> */}

                <div style={entryStyle}>
                    Fund{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select fund"
                                defaultValue={[]}
                                onChange={handleChangeFund}
                                options={fundSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.fund_name}
                    </span>}
                </div>

                {/* <div style={entryStyle}>
                    Type{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="multiple"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select type"
                                defaultValue={[]}
                                onChange={handleChangeType}
                                options={typeSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.type}
                    </span>}
                </div> */}

                {investment !== null && <div style={entryStyle}>
                    Type:
                    <span>
                        {' ' + investment.type}
                    </span>
                </div>}

                <div style={entryStyle}>
                    Method{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select method"
                                defaultValue={["Cashout"]}
                                onChange={handleChangeMethod}
                                options={methodSelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.method[0].toUpperCase() + investment.method.slice(1)}
                    </span>}
                </div>

                <div style={entryStyle}>
                    Amount{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <input style={{ ...inputStyle, ...{ width: "150px" } }}
                            onInput={e => setAmount(e.target.value)}>
                        </input>
                    }
                    {amount > 0 && `(${USDollar.format(amount)})`}
                    {investment !== null && <span>
                        {' ' + USDollar.format(investment.amount)}
                    </span>}
                </div>

                <div style={entryStyle}>
                    Start Date{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <input type="date" onChange={e => setStartDate(e.target.value)}></input>}
                    {investment !== null && <span>
                        {' ' + investment.funding_receipt_date.slice(0, 10)}
                    </span>}
                </div>

                <div style={entryStyle}>
                    End Date{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <input type="date" onChange={e => setEndDate(e.target.value)}></input>}
                    {investment !== null && <span>
                        {' ' + investment.actual_redemption_date.slice(0, 10)}
                    </span>}
                </div>

                <div style={entryStyle}>
                    Annual Interest Rate{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null && <input style={inputStyle}
                        onInput={e => setInterest(e.target.value)}></input>}
                    {investment !== null && <span>
                        {' ' + investment.annual_interest_rate}
                    </span>}%
                </div>

                <div style={entryStyle}>
                    Payout Frequency{investment === null && <span style={{ color: "red" }}>*</span>}:
                    {investment === null &&
                        <span>
                            <Select
                                showSearch
                                mode="tag"
                                style={{ width: '300px', marginLeft: "5px" }}
                                placeholder="select frequency"
                                defaultValue={[]}
                                onChange={handleChangeFrequency}
                                options={frequencySelectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span>}
                    {investment !== null && <span>
                        {' ' + investment.payout_frequency}
                    </span>}
                </div>
                {investment !== null && investment.type.toLowerCase().includes("debt") && <div style={entryStyle}>
                    Principal Takeout:

                    {principalTakenOut
                        .sort((a, b) => {
                            if (a.timestamp < b.timestamp) {
                                return -1
                            }
                            return 1
                        })
                        .map((principalTakenOutEntry, idx) => {
                            return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{}}>
                                    {principalTakenOutEntry.timestamp}
                                </div>

                                <div style={{ margin: "0 20px" }}>
                                    {USDollar.format(principalTakenOutEntry.amount)}
                                </div>

                                <div style={{}}>
                                    {principalTakenOutEntry.status[0].toUpperCase() + principalTakenOutEntry.status.slice(1)}
                                </div>
                            </div>
                        })}

                    {principalTakeOut.map((principalTakeOutEntry, idx) => {
                        return <div key={idx}>
                            <input
                                type="date"
                                value={principalTakeOutEntry[0]}
                                onChange={(e) => {
                                    if (new Date(e.target.value + "T00:00:00-07:00") < new Date()) {
                                        alert("Please select a future date")
                                        return
                                    }
                                    setPrincipalTakeOut([
                                        ...principalTakeOut.slice(0, idx),
                                        [e.target.value, principalTakeOutEntry[1]],
                                        ...principalTakeOut.slice(idx + 1),
                                    ])
                                }}
                            />

                            <input
                                value={principalTakeOutEntry[1]}
                                style={inputStyle}
                                onChange={(e) => {
                                    const val = e.target.value.trim() === "" ? 0 : e.target.value
                                    setPrincipalTakeOut([
                                        ...principalTakeOut.slice(0, idx),
                                        [principalTakeOutEntry[0], parseInt(val)],
                                        ...principalTakeOut.slice(idx + 1),
                                    ])
                                }}
                            ></input>

                            {USDollar.format(principalTakeOutEntry[1])}
                        </div>
                    })}

                    <div style={{}}>
                        <button style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "30px",
                            background: "rgb(17 32 86)",
                            color: "white",
                            cursor: "pointer",
                            fontSize: "30px",
                            display: "flex",
                            alignItems: "center",
                            placeContent: "center"
                        }}
                            onClick={addPrincipalTakeOutEntry}
                        >
                            +
                        </button>
                    </div>
                </div>}

                <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <button style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={saveInvestment}
                    >
                        Save
                    </button>
                    {investment !== null && <button style={{
                        height: "30px",
                        width: "70px",
                        borderRadius: "30px",
                        background: "red",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={deleteInvestment}
                    >
                        Delete
                    </button>}
                </div>
            </div>}
    </>
}