import { Divider, Row } from "antd"
import www from "../assets/img/www.png"
import phone from '../assets/img/phone.png'
import emailImg from '../assets/img/email.png'
import address from "../assets/img/address.png"
import mainLogo from '../assets/img/logo-fullsize.png';
import { isPhoneSize } from "utils/util"

export function CompanyInfo(props) {
    const { action } = props
    return <>
        <img style={{ width: '260px' }} src={mainLogo} alt="main-logo" />
        {/* <h3>AlphaX RE Capital</h3> */}
        <p>
            AlphaX RE Capital is a prominent real estate investment and development company deeply rooted in the Bay Area. Our expertise lies in investing in Single Family Residential properties in California. With merely three years of industry experience, we have successfully expanded our business to key counties in California such as Santa Clara County and Orange County. Our strategic approach involves closely monitoring market dynamics and cycles to enhance property value and maximize returns on investment. Over 300 projects, AlphaX has achieved an impressive average annual return rate of 15%. Please click the link below to schedule an 1 on 1 meeting for more information!
        </p>
        {action && action === "Invest" ?
            <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                <button style={{ background: "rgb(17 32 86)", color: "white", width: "100px", height: "30px", borderRadius: "15px", cursor: "pointer" }}>
                    Invest
                </button>
            </a> :
            <a target="_blank" rel="noopener noreferrer" href='https://calendly.com/alphax-investment/30min' style={{ color: "red" }}>
                <button style={{ background: "rgb(17 32 86)", color: "white", width: "150px", height: "30px", borderRadius: "15px", cursor: "pointer" }}>
                    Schedule a meeting
                </button>
            </a>}

        <div style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={phone}></img>
            </div>
            <a style={{ alignContent: "center" }} href="tel:818-818-3888">(818) 818-3888</a>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={emailImg}></img>
            </div>
            <a style={{ alignContent: "center" }} href="mailto:info@alphax-capital.com">
                info@alphax-capital.com
            </a>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={address}></img>
            </div>
            <a style={{ alignContent: "center" }} target="_blank" rel="noopener noreferrer" href='https://maps.app.goo.gl/i2Qw8PjCGdXPUP7m8'>
                97 Boston Ave, San Jose, CA 95128
            </a>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginBottom: isPhoneSize() ? "200px" : "" }}>
            <div style={{ width: "30px", height: "30px", display: "flex" }}>
                <img src={www}></img>
            </div>
            <a style={{ alignContent: "center" }} target="_blank" rel="noopener noreferrer" href='https://www.alphax-capital.com'>
                www.alphax-capital.com/
            </a>
        </div>
    </>
}