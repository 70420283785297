import { AutoComplete, Divider, Form, Input, Layout, Popconfirm, Table, message, Typography, InputNumber, TableProps } from 'antd';
import api from 'api';
import React, { useEffect, useState } from 'react';
import { get } from 'utils/fetch';
import './tables.css';


type TableName = {
  table_name: string
}

type TableColumn = {
  column_name: string
}

type Item = {
  key: string
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
}
const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const Tables: React.FC = () => {
  const [tables, setTables] = useState<TableName[]>([]);
  const [currentTable, setCurrentTable] = useState('');
  const [tableData, setTableData] = useState<any[]>([])
  const [tableNameIDInputValue, onTableNameIDInputValueChange] = useState<string>('');
  const [tableNameID, setTableNameID] = useState<string>('');
  const [tableColumns, setTableColumns] = useState<TableColumn[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const displayErrorMessage = (message: string) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  async function fetchTableList() {
    await get(
      api.table.list,
      setTables,
      (errors: any[]) => errors.forEach(err => {
        displayErrorMessage(err.message);
      })
    );
  }
  useEffect(() => {
    fetchTableList();
  }, []);
  useEffect(() => {
    if (tableNameID) {

      get(
        api.table.getDataAll(tableNameID),
        ({ data, columns }) => {
          for (let i = 0; i < data.length; i++) {
            data[i]['key'] = String(i)
            for (let { column_name } of columns) {
              if (column_name in data[i]) {
                //console.log(data[i][column_name], data[i][column_name] == null)
                if (data[i][column_name] === null || data[i][column_name] === '') {
                  data[i][column_name] = '-';
                }
              }
            }
          }
          //console.log(data);
          setTableData(data);
          setTableColumns(columns);
        },
        (errors: any[]) => errors.forEach(err => {
          displayErrorMessage(err.message);
        })
      );
    }
  }, [tableNameID])
  const onNameIDSearch = (value: string) => {
    if (!tables.find(x => x.table_name === value)) {
      messageApi.error("Please enter a valid fund name ID");
      return;
    }
    setTableNameID(value);
  }


  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setTableData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      //console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns: TableProps['columns'] = [
    {
      title: 'operation',
      dataIndex: 'operation',
      editable: false,
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
    ...tableColumns.map(x => {
      return {
        title: x.column_name,
        dataIndex: x.column_name,
        editable: true,
      }
    })

  ].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return <Layout style={{ maxWidth: '100%' }}>
    {contextHolder}
    <h3>Select a table to view</h3>
    <div className="table-choose">
      <AutoComplete
        value={tableNameIDInputValue}
        onChange={onTableNameIDInputValueChange}
        options={tables.map((x) => ({
          value: x.table_name,
        }))}
      >
        <Input.Search
          onSearch={onNameIDSearch}
          size="large"
          placeholder="Search fund name ID"
        />
      </AutoComplete>
    </div>
    <Divider />
    <div className='tables'>
      <Form form={form} component={false}>
        <Table
          bordered
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={{

            onChange: cancel,
          }}
          dataSource={tableData}

          columns={mergedColumns}
        >

        </Table>
      </Form>
    </div>
  </Layout>
};

export default Tables;