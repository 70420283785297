import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState } from "react"
import { Select, Space, message, Spin } from "antd"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"

export function FundInsertion(props) {

    const { fund, setFundId, holdingCompanies } = props

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [saving, setSaving] = useState(false)
    const [holdingCompaniesInvested, setHoldingCompaniesInvested] = useState([]) // companies invested before opening this page
    const [name, setName] = useState(fund === null || fund.name === null ? "" : fund.name)
    const [type, setType] = useState(fund === null || fund.type === null ? "" : fund.type)
    const [companyIdxSelected, setCompanyIdxSelected] = useState([])
    const [accountsByFund, setAccountsByFund] = useState([])
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    // holding company -> percentage (how much percent of the company is fund investing)
    const [investingPercent, setInvestingPercent] = useState({})

    useEffect(() => {
        const companyIdsInvested = holdingCompaniesInvested.map((company, idx) => company.id)
        // console.log(propertyIdsInvested)
        setInvestingPercent(
            companyIdxSelected.reduce((acc, cur) => {
                if (cur in investingPercent) {
                    acc[cur] = investingPercent[cur]
                } else if (companyIdsInvested.includes(cur)) {
                    acc[cur] = holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company === null ? 0 : holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company
                } else {
                    acc[cur] = 0
                }
                return acc
            }, {})
        )
    }, [companyIdxSelected])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchHoldingCompanies(id) {
        await get(api.manager.HoldingCompaniesByFund(id), setHoldingCompaniesInvested, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchAccounts(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.accountsByFund(id), setAccountsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }
    // console.log(accountsByFund)
    useEffect(() => {
        // if fund is not null
        if (fund !== null) {
            // retrieve funds associate with it
            fetchAccounts(fund.id)
        }
    }, [])

    useEffect(() => {
        setCompanyIdxSelected(holdingCompaniesInvested.map(holdingCompaniesInvested => holdingCompaniesInvested.id))
    }, [holdingCompaniesInvested])

    useEffect(() => {
        if (fund === null) {
            setLoading(false)
            return
        }
        fetchHoldingCompanies(fund.id)
    }, [fund])

    var holdingCompany2idx = {}
    for (const holdingCompany of holdingCompanies) {
        holdingCompany2idx[holdingCompany.name] = holdingCompany.id
    }
    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (name.trim().length === 0) {
            invalidFields.push("Please fill name.")
        }

        if (type.length === 0) {
            invalidFields.push("Please select type.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            setFundId(0)
            return
        }
        // setSaving(true)
        setName(name.trim())
        // selected in current page
        for (const id of companyIdxSelected) {
            //console.log("selected company id", id)
        }
        // invested before opening the page
        for (const company of holdingCompaniesInvested) {
            //console.log("Invested company id", company.id, "(" + company.name + ")")
        }

        // id invested previously
        const companyIdInvested = holdingCompaniesInvested.length === 0 ? [] : holdingCompaniesInvested.map(company => company.id)
        // newly added company id (selected but not previously invested)
        const addedCompanyId = companyIdxSelected.length === 0 ? [] : companyIdxSelected.filter(id => !companyIdInvested.includes(id));
        // removed company id (previously invested but not selected)
        const removedCompanyId = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => !companyIdxSelected.includes(id))

        const keptCompanyIds = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => companyIdxSelected.includes(id))

        const body = JSON.stringify({
            name,
            type,
            addedCompanyId,
            removedCompanyId,
            keptCompanyIds,
            investingPercent
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        if (fund === null) {
            await fetch(api.manager.funds, options); // new record
        } else {
            await fetch(api.manager.fund(fund.id), options); // old record
        }
        setFundId(0)
    }
    const handleChange = (companies) => {
        // companies: companies names
        setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        setDirty(true)
    };
    const selectionOptions = holdingCompanies
        .map(company => {
            return {
                label: company.name,
                value: company.name,
                desc: company.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        // setFundIdSelected(fundNameToId[fund])
        const typeRes = type.sort((a, b) => a < b ? -1 : 1).join("/")
        setType(typeRes)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]

    function deleteFund() {
        if (accountsByFund.length > 0 || holdingCompaniesInvested.length > 0) {
            setCreationErrorMessages([
                ...accountsByFund
                    .map(account => {
                        return `Current fund is being invested by ${account.account_name}.`
                    }),
                ...holdingCompaniesInvested
                    .map(company => {
                        return `Current fund is investing ${company.name}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.fund(fund.id), options);
        const data = await result.json()
        setFundId(0)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <div>

            {fund !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Fund: ${fund.name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }


            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setFundId(0)}
                ></img>
            </div>

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={entryStyle}>
                Name<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                    value={name}
                    onInput={(e) => {
                        setName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                Type{fund === null && <span style={{ color: "red" }}>*</span>}:
                {fund === null &&
                    <span>
                        <Select
                            showSearch
                            mode="multiple"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select type"
                            defaultValue={[]}
                            onChange={handleChangeType}
                            options={typeSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                {fund !== null && <span>
                    {' ' + fund.type}
                </span>}
            </div>

            <div style={entryStyle}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Companies: </div>
                    <span>
                        <Select
                            mode="multiple"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select holding companies"
                            defaultValue={holdingCompaniesInvested.map(holdingCompany => holdingCompany.name)}
                            onChange={handleChange}
                            options={selectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>
            </div>

            <div style={entryStyle}>
                Investing Percent:
                {companyIdxSelected.map((companyId, idx) => {
                    return <div key={idx} style={{ display: "flex" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {holdingCompanies.filter((company) => company.id === companyId)[0].name}:
                        </div>
                        <input style={{
                            outline: 'none',
                            border: "none",
                            borderBottom: "1px solid black",
                            background: 'transparent',
                            textAlign: "right"
                        }}
                            value={investingPercent[companyId] !== undefined ? investingPercent[companyId] : ""}
                            onChange={e => {
                                // Update state with the raw string input value, allowing "."
                                const value = e.target.value;

                                // Set the state with the updated value (as a string)
                                setDirty(true);
                                setInvestingPercent({ ...investingPercent, [companyId]: value });
                            }}
                            onBlur={e => {
                                // Convert to float when input loses focus
                                const value = parseFloat(e.target.value) || 0; // Default to 0 if invalid
                                setInvestingPercent({ ...investingPercent, [companyId]: value });
                            }}
                        ></input>%
                    </div>
                })}
            </div>

            {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveProperty}>
                    Save
                </button>
                {fund !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteFund}
                >
                    Delete
                </button>}
            </div>}
            {saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}>
                    Saving
                </button>
            </div>}
        </div>}
    </>
}