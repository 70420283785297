import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import axios from 'axios';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { property } from 'lodash';
import { propertyToAddress } from 'utils/util';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom larger marker
const largeIcon = L.icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [38, 57], // 1.5 times the normal size (normal is [25, 41])
    iconAnchor: [19, 57], // Adjusted for 1.5 times size
    shadowSize: [57, 57], // Adjust shadow size accordingly
    shadowAnchor: [19, 57],
    popupAnchor: [0, -57] // Adjust popup anchor as well
});

function MyComponent(props) {
    const { position, initZoom } = props
    const map = useMap();
    React.useEffect(() => {
        map.setView(position, initZoom);
    }, [position]);
    return null
}

function Map(props) {

    const { position, setPosition, properties, setAddress } = props;
    const initZoom = 10

    function handleMarkerClick(e) {
        setPosition([e.latlng.lat, e.latlng.lng])

        setAddress(propertyToAddress(properties
            .filter(property => property.latitude === e.latlng.lat && property.longitude === e.latlng.lng)[0]))
    }

    return (
        <MapContainer center={position} maxZoom={23} zoom={initZoom} style={{ width: '100%', zIndex: 0 }}>
            <TileLayer
                maxZoom={23}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MyComponent position={position} initZoom={initZoom} />
            {properties.map((property, idx) => {
                return <Marker key={idx}
                    position={[property.latitude, property.longitude]}
                    eventHandlers={{ click: handleMarkerClick }}
                >
                </Marker>
            })}
            <Marker
                position={position}
                icon={largeIcon}
            >
            </Marker>
        </MapContainer>
    );
}

export default Map;