// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invested-properties-container {
    display: flex;
    width: 100%;
}
.invested-properties {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Investor/InvestedProperties.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;AACA;IACI,WAAW;AACf","sourcesContent":[".invested-properties-container {\n    display: flex;\n    width: 100%;\n}\n.invested-properties {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
