import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DescriptionsProps, Spin, Table, Typography, message } from 'antd';
import api from 'api';
import { get } from 'utils/fetch';
import { BasicInfoKeyNameMap, financialInfoKeyNameMap } from './const';
import { TabComponentProps } from 'types/common';
import { makeColumns, makeTableDataSource } from 'utils/format';

const { Text } = Typography;

const PropertyFinancials: React.FC<TabComponentProps> = ({ nameid }) => {
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    async function fetchPropertyBasicInfo(nameid: string) {
        setLoading(true);
        await get(
            api.property.getBasicInfo(nameid),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchPropertyBasicInfo(nameid);
        }
    }, [nameid]);

    let basicDescriptionItems: DescriptionsProps['items'] = [];
    Object.keys(BasicInfoKeyNameMap).forEach((key: string) => {
        if (key in basicInfo) {
            basicDescriptionItems?.push({
                key,
                label: BasicInfoKeyNameMap[key],
                children: basicInfo[key],
            });
        }
    });

    let basicSection = null;
    if (loading) {
        basicSection = <Spin />;
    }
    else if (!nameid) basicSection = <span>Please enter a property id from selection</span>;
    else if (nameid && basicInfo?.address) basicSection = (
        <>
            <h3>Financial</h3>
            <Table
                bordered
                pagination={false}
                columns={makeColumns('Financial Info', 'Details')}
                dataSource={makeTableDataSource(financialInfoKeyNameMap, basicInfo)}
            />
        </>
    );

    return <>
        {contextHolder}
        {basicSection}
    </>;
}
export default PropertyFinancials;