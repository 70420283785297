import { createContext, useContext } from "react";

export const LoginEmailContext = createContext<string | undefined>("init")

export function useLoginEmailContext() {
    const loginEmail = useContext(LoginEmailContext)
    // if (loginEmail === undefined) {
    //     throw new Error('loginEmail being undefined')
    // }
    return loginEmail
}