import { useState } from "react";
import { isPhoneSize } from "utils/util";

export function RecordManagerTab(props) {
    const { text, tabSelection, setTabSelection } = props
    const [hover, setHover] = useState(false);
    const selected = tabSelection === text
    const color = selected ? "rgb(22, 119, 255)" : "rgb(191 191 191)"
    return <>
        <div style={isPhoneSize() ? {
            width: "100%",
            textAlign: "center",
            borderBottom: hover ? `1px solid rgb(22, 119, 255)` : `1px solid ${color}`,
            color: color,
            height: "30px",
            fontSize: "12px",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "0 10px",
            placeContent: "center",
        } : {
            width: "100%",
            textAlign: "center",
            borderBottom: hover ? `1px solid rgb(22, 119, 255)` : `1px solid ${color}`,
            color: color,
            height: "30px",
            fontSize: "13px",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "0 20px"
        }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(e) => setTabSelection(e.target.innerHTML)}
        >
            {text}
        </div>
    </>
}