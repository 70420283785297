const api = {
    investor: {
        list: `/api/investor/list`,
        getDebtRecord: (nameid: string) => `/api/investor/${nameid}/debt_record`, // fetchDebtRecord(3)
        getBasicInfo: (email: string) => `/api/investor/${email}/info`, // fetchInvestorBasicInfo(1)
        getInvestorImage: (nameid: string) => `/api/investor/${nameid}/image`,
        weightToProperties: (nameid: string) => `/api/investor/${nameid}/weight_to_property`,
        getInvestments: (nameid: string) => `/api/investor/${nameid}/investments`,
        getActiveInvestments: (nameid: number) => `/api/investor/${nameid}/investments/active`, // fetchInvestorInvestments(2)
        getActiveInvestmentsTable: (nameid: number) => `/api/investor/${nameid}/investments/active/table`, // fetchInvestorInvestments(2)
        getClosedInvestmentsTable: (nameid: number) => `/api/investor/${nameid}/investments/closed/table`, // fetchInvestorInvestments(2)
        getActiveClosedInvestments: (nameid: number) => `/api/investor/${nameid}/investments/closed`,
        getTransactions: (nameid: number) => `/api/investor/${nameid}/transactions`,
        // insertInvestor: (email: string) => `/api/investor/${nameid}/transactions`,
    },

    transaction: {
        setStatus: (id: number) => `/api/transaction/status/${id}`,
        // get all transactions from active investments
        activeTransactions: (startDate: string, endDate: string) => `/api/transaction/?startDate=${startDate}&endDate=${endDate}`,
        allActiveTransactions: `/api/transaction/all`,
    },

    fund: {
        list: `/api/fund/list`,
        investorList: (email: string) => `/api/fund/${email}/list`, // fund list of current investors
        uninvestedList: (email: string) => `/api/fund/${email}/uninvested-funds`,
        getBasicInfo: (fund_id: number) => `/api/fund/${fund_id}/info`,
        weightToProperties: (nameid: string) => `/api/fund/${nameid}/weight_to_property`,
        getDirectInvestmentsTo: (nameid: string) => `/api/fund/${nameid}/investments`,
        getDirectActiveInvestmentsTo: (fund_id: number) => `/api/fund/${fund_id}/investments/active`,
        getFundProperty: (fundId: number) => `/api/fund/${fundId}/property`
    },

    property: {
        list: `/api/property/list`,
        getBasicInfo: (nameid: string) => `/api/property/${nameid}/info`,
    },

    file: {
        upload: `/api/file/upload`,
        download: (filename: string) => `/api/file/download`,
        getPhotos: (fundId: number) => `/api/file/photo/${fundId}`,
        getPdfs: (fundId: number) => `/api/file/pdfs/${fundId}`
    },

    misc: {
        getNameIDMapping: `/api/misc/nameid_mapping`,
    },

    table: {
        list: `/api/table/list`,
        getDataAll: (tableName: string) => `/api/table/${tableName}/data`,
    },
    manager: {
        investors: '/api/manager/investors', // get all investors | insert new investor
        investor: (id: number) => `/api/manager/investor/${id}`, // update an investor
        accountsByInvestor: (id: number) => `/api/manager/investor/${id}/accounts`, // get accounts held by investor

        accounts: '/api/manager/accounts', // get all accounts | insert new account
        account: (id: number) => `/api/manager/account/${id}`, // update an account
        fundsByAccount: (id: number) => `/api/manager/account/${id}/funds`,
        investorsByAccount: (id: number) => `/api/manager/account/${id}/investors`,

        investments: `/api/manager/investments`, // get all investments | insert new investment
        investment: (id: number) => `/api/manager/investment/${id}`, // update an investment
        investmentPrincipalTakeOut: (id: number) => `/api/manager/investment/${id}/principalTakeout`,

        funds: `/api/manager/funds`, // get all funds | insert new fund
        fund: (id: number) => `/api/manager/fund/${id}`, // update a fund
        HoldingCompaniesByFund: (id: number) => `/api/manager/fund/${id}/holdingCompanies`, // get companies invested by fund
        accountsByFund: (id: number) => `/api/manager/fund/${id}/accounts`,

        holdingCompanies: `/api/manager/holdingCompanies`, // get all holdingCompanies | insert new company
        holdingCompany: (id: number) => `/api/manager/holdingCompany/${id}`, // update a holdingCompany
        propertiesByHoldingCompany: (id: number) => `/api/manager/holdingCompany/${id}/properties`, // get properties invested by company
        fundByHoldingCompany: (id: number) => `/api/manager/holdingCompany/${id}/funds`, // get funds that are investing the company

        properties: `/api/manager/properties`, // get all properties
        property: (id: number) => `/api/manager/property/${id}`, // update a property
        holdingCompaniesByProperty: (id: number) => `/api/manager/property/${id}/holdingCompanies`, // companies that hold property
    }
};

export default api;

export type APISectionKeys = keyof typeof api;
export type APISection = typeof api[APISectionKeys];