import { useEffect, useState } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input } from "antd";
import { InvestorInsertion } from "pages/RecordInsertion/InvestorInsertion";

export function InvestorManager() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [investors, setInvestors] = useState([])
    const [investorId, setInvestorId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")
    const [accounts, setAccounts] = useState([])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchInvestors() {
        await get(api.manager.investors, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (investorId !== 0) return
        fetchInvestors()
        fetchAccounts()
    }, [investorId])

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && investorId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on name or email"
                />
            </div>
            <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setInvestorId(-1)}>
                    Add
                </button>
            </div>
            {investors
                .filter(investor =>
                    investor.legal_name.toLowerCase().includes(filteredText) || investor.email.toLowerCase().includes(filteredText))
                .map((investor, idx) => {
                    return <div key={idx} style={{
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        borderBottom: "1px solid blue",
                        cursor: "pointer"
                    }}
                        onClick={() => setInvestorId(investor.id)}
                    >
                        {`${investor.legal_name} (${investor.email})`}
                    </div>
                })}

        </>
        }
        {!loading && investorId > 0 && <>
            <InvestorInsertion investor={investors.filter(investor => investor.id === investorId)[0]} setInvestorId={setInvestorId} accounts={accounts} />
        </>}
        {!loading && investorId < 0 && <>
            <InvestorInsertion investor={null} setInvestorId={setInvestorId} accounts={accounts} />
        </>}
    </>
}