import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { FiefAuthProvider } from '@fief/fief/react';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <FiefAuthProvider
            baseURL={process.env.REACT_APP_FIEF_BASE_URL || ''}
            clientId={process.env.REACT_APP_FIEF_CLIENT_ID || ''}
        >
            <App />
        </FiefAuthProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
