import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState } from "react"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { message } from "antd";
import { propertyToAddress } from "utils/util"

export function PropertyInsertion(props) {
    const { property, setPropertyId } = props

    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    // holding companies holding the current property
    const [holdingCompanies, setHoldingCompanies] = useState([])
    const [saving, setSaving] = useState(false)
    const [address1, setAddress1] = useState(property === null || property.address === null ? "" : property.address)
    const [address2, setAddress2] = useState(property === null || property.address2 === null ? "" : property.address2)
    const [city, setCity] = useState(property === null || property.city === null ? "" : property.city)
    const [state, setState] = useState(property === null || property.state === null ? "" : property.state)
    const [zip, setZip] = useState(property === null || property.zip === null ? "" : property.zip)
    const [messageApi, contextHolder] = message.useMessage();
    const [deleteMessage, setDeleteMessage] = useState("") // need to be delete to delete
    const [showDeletionBox, setShowDeletionBox] = useState(false)

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    async function fetchHoldingCompanies(id) {
        // retrieve holding companies associate with property
        await get(api.manager.holdingCompaniesByProperty(id), setHoldingCompanies, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    useEffect(() => {
        // if property is not null
        if (property !== null) {
            // retrieve holding companies associate with it
            fetchHoldingCompanies(property.id)
        }
    }, [])
    // console.log(holdingCompanies)
    function canSubmit() {
        var invalidFields = []
        if (address1.trim().length === 0) {
            invalidFields.push("Please fill in address line 1.")
        }
        if (city.trim().length === 0) {
            invalidFields.push("Please fill in city.")
        }
        if (state.trim().length === 0) {
            invalidFields.push("Please fill in state.")
        }
        if (zip.trim().length === 0) {
            invalidFields.push("Please fill in zip.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }
    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            setPropertyId(0)
            return
        }
        setSaving(true)
        setAddress1(address1.trim())
        setAddress2(address2.trim())
        setCity(city.trim())
        setState(state.trim())
        setZip(zip.trim())
        const fullAddress = address2 !== null && address2.trim().length > 0 ?
            `${address1} ${address2}, ${city}, ${state} ${zip}` :
            `${address1}, ${city}, ${state} ${zip}`
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(fullAddress)}`);
        const data = await response.json()
        var latitude = 37.32550095925802
        var longitude = -121.92818162476449
        if (data.length > 0) {
            latitude = parseFloat(data[0].lat)
            longitude = parseFloat(data[0].lon)
        }
        const body = JSON.stringify({
            address1,
            address2,
            city,
            state,
            zip,
            fullAddress,
            latitude,
            longitude
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        if (property === null) {
            fetch(api.manager.properties, options);
        } else {
            fetch(api.manager.property(property.id), options);
        }
        setPropertyId(0)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.property(property.id), options);
        const data = await result.json()
        setPropertyId(0)
    }

    async function deleteProperty() {
        if (holdingCompanies.length > 0) {
            setCreationErrorMessages(holdingCompanies.map(holdingCompany => {
                return `Current property is held by ${holdingCompany.name}`
            }))
            return
        }
        setShowDeletionBox(true)
    }

    return <div>
        {property !== null && showDeletionBox &&
            <DeleteConfirmation
                title={`Address: ${propertyToAddress(property)}`}
                setShowDeletionBox={setShowDeletionBox}
                deletionCallback={deletionCallback}
            />
        }
        <div style={{ margin: "20px" }}>
            <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                src={backArrow}
                onClick={() => setPropertyId(0)}
            ></img>
        </div>

        {creationErrorMessages.map((message, idx) => {
            return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
        })}

        <div style={entryStyle}>
            Address Line 1<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                value={address1}
                onInput={(e) => {
                    setAddress1(e.target.value)
                    setDirty(true)
                }}></input>
        </div>

        <div style={entryStyle}>
            Address Line 2: <input style={inputStyle}
                value={address2}
                onInput={(e) => {
                    setAddress2(e.target.value)
                    setDirty(true)
                }}></input>
        </div>

        <div style={entryStyle}>
            City<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                value={city}
                onInput={(e) => {
                    setCity(e.target.value)
                    setDirty(true)
                }}></input>
        </div>

        <div style={entryStyle}>
            State<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                value={state}
                onInput={(e) => {
                    setState(e.target.value)
                    setDirty(true)
                }}></input>
        </div>

        <div style={entryStyle}>
            Zipcode<span style={{ color: "red" }}>*</span>: <input style={inputStyle}
                value={zip}
                onInput={(e) => {
                    setZip(e.target.value)
                    setDirty(true)
                }}></input>
        </div>

        {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
            <button style={{
                height: "30px",
                width: "50px",
                borderRadius: "30px",
                background: "rgb(17 32 86)",
                color: "white",
                cursor: "pointer",
            }}
                onClick={saveProperty}>
                Save
            </button>

            {property !== null && <button style={{
                height: "30px",
                width: "70px",
                borderRadius: "30px",
                background: "red",
                color: "white",
                cursor: "pointer",
            }}
                onClick={deleteProperty}
            >
                Delete
            </button>}
        </div>}
        {saving && <div style={{ margin: "20px" }}>
            <button style={{
                height: "30px",
                width: "70px",
                borderRadius: "30px",
                background: "rgb(17 32 86)",
                color: "white",
                cursor: "pointer",
            }}>
                Saving
            </button>
        </div>}
    </div>
}