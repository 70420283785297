import React, { useEffect, useState } from 'react';
import { TabComponentProps } from 'types/common';
import _ from 'lodash';
import { Spin, Table, message } from 'antd';
import api from 'api';
import { get } from 'utils/fetch';

import './BasicInfo.css';

const BasicInfo: React.FC<TabComponentProps> = ({ nameid }) => {
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    async function fetchInvestorBasicInfo(nameid: string) {
        setLoading(true);
        await get(
            api.investor.getBasicInfo(nameid),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchInvestorBasicInfo(nameid);
        }
    }, [nameid]);

    let content = null;
    if (loading) content = <Spin />;
    else if (!nameid) content = <span>Please enter a user id from selection</span>;
    else if (nameid && basicInfo?.legal_name) content = (
        <Table pagination={false}
            columns={[{
                title: 'Investor Info',
                dataIndex: 'name'
            }, {
                title: 'Detail',
                dataIndex: 'value'
            }]}

            dataSource={[{
                name: 'Name ID',
                key: basicInfo.nameid,
                value: basicInfo.nameid
            }, {
                name: 'Legal Name',
                key: basicInfo.legal_name,
                value: basicInfo.legal_name
            }, {
                name: 'Mailing Address',
                key: basicInfo.mailing_address,
                value: basicInfo.mailing_address ||  '-',
            }, {
                name: 'Email',
                key: basicInfo.email,
                value: basicInfo.email ||  '-',
            }, {
                name: 'Phone',
                key: basicInfo.phone,
                value: basicInfo.phone ||  '-',
            }, {
                name: 'Date of Birth',
                key: new Date(basicInfo.date_of_birth).toISOString().split('T')[0],
                value: new Date(basicInfo.date_of_birth).toISOString().split('T')[0] ||  '-',
            }, {
                name: 'Tax ID / SSN',
                key: basicInfo.tax_id_ssn,
                value: basicInfo.tax_id_ssn ||  '-',
            }, {
                name: 'Citizenship',
                key: basicInfo.citizenship,
                value: basicInfo.citizenship ||  '-',
            }, {
                name: 'Accredited Investor',
                key: basicInfo.accredited_investeor ? 'YES' : 'NO',
                value: basicInfo.accredited_investeor ? 'YES' : 'NO',
            },]}
        />
    );

    return <>
        {contextHolder}
        <h3>Investor Information</h3>
        <div className='basic-info'>
            {content}
        </div>
    </>;
}

export default BasicInfo;
