import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutCallback() {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear any local auth state
        localStorage.removeItem('token');
        // Add any other necessary cleanup here

        // Redirect to login page
        navigate('/login');
    }, [navigate]);

    return <div>Logging out...</div>;
};

export default LogoutCallback;