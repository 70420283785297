
export const BasicInfoKeyNameMap: Record<string, string> = {
    'nameid': 'Name ID',
    'address': 'Address',
    'city': 'City',
    'state': 'State',
    'zip': 'Zip Code',
    'titleholder': 'Title Holder',
    'listing_status': 'Listing Status',
    'borrower': 'Borrower',
};

export const TitleInfoKeyNameMap: Record<string, string> = {
    'share': 'Share',
    'shareholder': 'Share Holder',
    'prelim': 'Prelim',
    'grand_deed_prelim': 'Grand Deed Prelim',
};

export const InvestmentInfoKeyNameMap: Record<string, string> = {
    'investor_list': 'Investor List',
    'investor_cash_invested': 'Investor Cash Invested',
    'customer_type': 'Customer Type',
    'om': 'OM',
    'proforma': 'Proforma',
};

export const financialInfoKeyNameMap: Record<string, string> = {
    'update_date': 'Last Updated',
    'zillow_estimate': 'Zillow Estimate Value',
    'redfin_estimate': 'Redfin Estimate Value',
    'after_repaid_value': 'After Repair Value',
    'as_is_value': 'As Is Value',
    'estimated_sale_price': 'Estimated Sale Price',
    'estimated_sale_closing_cost': 'Estimated Sale Closing Cost',
    'estimated_sale_refund': 'Estimated Sale Refund',
    'estimated_profit': 'Estimated Profit',
};

export const acquisitionInfoKeyNameMap: Record<string, string> = {
    'under_contract_date': 'Under Contract Date',
    'occupancy_status': 'Occupancy Status',
    'occupancy_status_note': 'Occupancy Status Note',
    'purchase_price': 'Purchase Price',
    'contingency_note': 'Contingency Note',
    'remove_contingency_date': 'Remove Contingency Date',
    'purchase_close_date': 'Purchase Close Date',
    'is1031': '1031',
    'transaction_folder': 'Transaction Folder',
};