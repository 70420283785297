import { useFiefAuth } from '@fief/fief/react';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const Callback: React.FC = () => {
    const fiefAuth = useFiefAuth();
    const navigate = useNavigate();

    useEffect(() => {
        fiefAuth.authCallback(`${window.location.protocol}//${window.location.host}/callback`).then(() => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let path = urlParams.get('state');
            if (path) {
                const u = new URL(path);
                path = u.pathname + u.search;
            } else {
                path = '/';
            }

            navigate(path);
        });
    }, [fiefAuth, navigate]);

    return <Spin size='large' />;
};

export default Callback;
