import { isAdmin } from "utils/util"
import { RecordManagerTab } from "./RecordManagerTab"
import { useEffect, useState } from "react";
import { InvestorManager } from "./InvestorManager";
import { PropertyManager } from "./PropertyManager";
import { HoldingCompanyManager } from "./HoldingCompanyManager";
import { FundManager } from "./FundManager";
import { InvestmentManager } from "./InvestmentManager";
import { isPhoneSize } from "utils/util";
import { AccountManager } from "./AccountManager";

export function RecordManager(props) {

    const [tabSelection, setTabSelection] = useState("Investors")

    const content = <div style={isPhoneSize() ? {
        height: "calc(100vh - 230px)"
    } : {
        height: "100vh"
    }}>
        <div style={{ display: "flex", flexDirection: "row", overflowX: "scroll", overflowY: "hidden", scrollbarWidth: "none" }}>
            <RecordManagerTab text={"Investors"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
            <RecordManagerTab text={"Accounts"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
            <RecordManagerTab text={"Investments"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
            <RecordManagerTab text={"Entities"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
            <RecordManagerTab text={"Companies"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
            <RecordManagerTab text={"Assets"} tabSelection={tabSelection} setTabSelection={setTabSelection} />
        </div>
        <div style={isPhoneSize() ? {
            height: "calc(100vh - 250px)", overflowY: "scroll", scrollbarWidth: "none"
        } : {
            height: "calc(100vh - 50px)", overflowY: "scroll", scrollbarWidth: "none"
        }}>
            <div style={{}}>
                {tabSelection === "Investors" && <InvestorManager />}
                {tabSelection === "Accounts" && <AccountManager />}
                {tabSelection === "Investments" && <InvestmentManager />}
                {tabSelection === "Entities" && <FundManager />}
                {tabSelection === "Companies" && <HoldingCompanyManager />}
                {tabSelection === "Assets" && <PropertyManager />}
                <div style={{ height: "150px" }}></div>
            </div>
        </div>
        {/* 
        
         */}
    </div>

    return <>
        {!isAdmin() && <>Not authorized</>}
        {isAdmin() && content}
    </>
}