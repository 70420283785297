import OfferingCard from "./OfferingCard"
import arrowLeft from "../assets/img/arrowLeft.png"
import arrowRight from "../assets/img/arrowRight.png"
import { useState } from "react"

export function OfferingGallery(props) {
    const { uninvestedFunds } = props
    const [curFundIdx, setCurFundIdx] = useState(0)

    function isFirstPage() {
        return curFundIdx === 0
    }

    function isLastPage() {
        return curFundIdx === uninvestedFunds.length - 1
    }

    return <div style={{ width: "100%", display: "flex", placeContent: "center" }}>
        {uninvestedFunds.length >= 1 &&
            <div style={{ display: 'flex', alignItems: "center" }}>
                <div style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    position: "relative",
                    left: '15px',
                    zIndex: isFirstPage() ? "0" : "1",
                    cursor: isFirstPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isFirstPage() && setCurFundIdx(curFundIdx - 1)}
                >
                    <img style={{ display: isFirstPage() ? "none" : "flex" }} src={arrowLeft}></img>
                </div>
                <div style={{}}>
                    <OfferingCard
                        name={uninvestedFunds[curFundIdx].name}
                        type={uninvestedFunds[curFundIdx].type}
                        target_offerings_total={uninvestedFunds[curFundIdx].target_offerings_total}
                        actual_raised_total={uninvestedFunds[curFundIdx].actual_raised_total}
                        link={`/offerings?id=${uninvestedFunds[curFundIdx].id}`}
                        description={uninvestedFunds[curFundIdx].description}
                    />
                </div>
                <div style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    position: "relative",
                    right: '15px',
                    zIndex: isLastPage() ? "0" : "1",
                    cursor: isLastPage() ? "auto" : 'pointer'
                }}
                    onClick={() => !isLastPage() && setCurFundIdx(curFundIdx + 1)}
                >
                    <img style={{ display: isLastPage() ? "none" : "flex" }} src={arrowRight}></img>
                </div>
            </div>
        }
    </div>
    // return <div style={{ display: 'flex', overflowX: "auto", scrollbarWidth: "none" }}>
    //     <div>
    //         <image src={www}></image>
    //     </div>
    //     {uninvestedFunds.map((fund, idx) => {
    //         return <OfferingCard
    //             name={fund.name}
    //             type="Debt"
    //             target_offerings_total={fund.target_offerings_total}
    //             actual_raised_total={fund.actual_raised_total}
    //             link="/offerings?nameid='e_00000001'"
    //             description={fund.description}
    //         />
    //     })}
    // </div>
}