import React, { createContext, useState } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
    const [globalEmail, setGlobalEmail] = useState("");

    return (
        <GlobalContext.Provider value={{ globalEmail, setGlobalEmail }}>
            {children}
        </GlobalContext.Provider>
    );
};
