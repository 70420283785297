import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState } from "react"
import { Select, Space, message, Spin } from "antd"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { propertyToAddress } from "utils/util"
import { USDollar } from "utils/format"
import { isPhoneSize } from "utils/util"
import { Email } from "pages/Email/Email"

export function FundInsertion(props) {

    const { fund, setFundId, holdingCompanies } = props

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [saving, setSaving] = useState(false)
    const [holdingCompaniesInvested, setHoldingCompaniesInvested] = useState([]) // companies invested before opening this page
    const [name, setName] = useState(fund === null || fund.name === null ? "" : fund.name)
    const [type, setType] = useState(fund === null || fund.type === null ? "" : fund.type)
    const [companyIdxSelected, setCompanyIdxSelected] = useState([])
    const [accountsByFund, setAccountsByFund] = useState([])
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    // holding company -> percentage (how much percent of the company is fund investing)
    const [investingPercent, setInvestingPercent] = useState({})
    const [investorsByFund, setInvestorsByFund] = useState([])
    const [propertiesByFund, setPropertiesByFund] = useState([])
    const [positionsByFund, setPositionsByFund] = useState([])
    const [view, setView] = useState("fund")
    const [emailResponse, setEmailResponse] = useState("placeholder")

    useEffect(() => {
        const companyIdsInvested = holdingCompaniesInvested.map((company, idx) => company.id)
        // console.log(propertyIdsInvested)
        setInvestingPercent(
            companyIdxSelected.reduce((acc, cur) => {
                if (cur in investingPercent) {
                    acc[cur] = investingPercent[cur]
                } else if (companyIdsInvested.includes(cur)) {
                    acc[cur] = holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company === null ? 0 : holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company
                } else {
                    acc[cur] = 0
                }
                return acc
            }, {})
        )
    }, [companyIdxSelected])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchHoldingCompanies(id) {
        await get(api.manager.HoldingCompaniesByFund(id), setHoldingCompaniesInvested, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchInvestorsByFund(id) {
        await get(api.manager.investorsByFund(id), setInvestorsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchPropertiesByFund(id) {
        await get(api.manager.propertiesByFund(id), setPropertiesByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchPositionsByFund(id) {
        await get(api.manager.positionsByFund(id), setPositionsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchAccounts(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.accountsByFund(id), setAccountsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }
    // console.log(accountsByFund)
    useEffect(() => {
        // if fund is not null
        if (fund !== null) {
            // retrieve funds associate with it
            fetchAccounts(fund.id)
        }
    }, [])

    useEffect(() => {
        setCompanyIdxSelected(holdingCompaniesInvested.map(holdingCompaniesInvested => holdingCompaniesInvested.id))
    }, [holdingCompaniesInvested])

    useEffect(() => {
        if (fund === null) {
            setLoading(false)
            return
        }
        fetchInvestorsByFund(fund.id)
        fetchPropertiesByFund(fund.id)
        fetchPositionsByFund(fund.id)
        fetchHoldingCompanies(fund.id)
    }, [fund])

    var holdingCompany2idx = {}
    for (const holdingCompany of holdingCompanies) {
        holdingCompany2idx[holdingCompany.name] = holdingCompany.id
    }
    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (name.trim().length === 0) {
            invalidFields.push("Please fill name.")
        }

        if (type.length === 0) {
            invalidFields.push("Please select type.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            setFundId(0)
            return
        }
        // setSaving(true)
        setName(name.trim())
        // selected in current page
        for (const id of companyIdxSelected) {
            //console.log("selected company id", id)
        }
        // invested before opening the page
        for (const company of holdingCompaniesInvested) {
            //console.log("Invested company id", company.id, "(" + company.name + ")")
        }

        // id invested previously
        const companyIdInvested = holdingCompaniesInvested.length === 0 ? [] : holdingCompaniesInvested.map(company => company.id)
        // newly added company id (selected but not previously invested)
        const addedCompanyId = companyIdxSelected.length === 0 ? [] : companyIdxSelected.filter(id => !companyIdInvested.includes(id));
        // removed company id (previously invested but not selected)
        const removedCompanyId = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => !companyIdxSelected.includes(id))

        const keptCompanyIds = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => companyIdxSelected.includes(id))

        const body = JSON.stringify({
            name,
            type,
            addedCompanyId,
            removedCompanyId,
            keptCompanyIds,
            investingPercent
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        if (fund === null) {
            await fetch(api.manager.funds, options); // new record
        } else {
            await fetch(api.manager.fund(fund.id), options); // old record
        }
        setFundId(0)
    }
    const handleChange = (companies) => {
        // companies: companies names
        setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        setDirty(true)
    };
    const selectionOptions = holdingCompanies
        .map(company => {
            return {
                label: company.name,
                value: company.name,
                desc: company.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        // setFundIdSelected(fundNameToId[fund])
        const typeRes = type.sort((a, b) => a < b ? -1 : 1).join("/")
        setType(typeRes)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]

    function deleteFund() {
        if (accountsByFund.length > 0 || holdingCompaniesInvested.length > 0) {
            setCreationErrorMessages([
                ...accountsByFund
                    .map(account => {
                        return `Current fund is being invested by ${account.account_name}.`
                    }),
                ...holdingCompaniesInvested
                    .map(company => {
                        return `Current fund is investing ${company.name}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.fund(fund.id), options);
        const data = await result.json()
        setFundId(0)
    }
    console.log(investorsByFund.map(investor => investor.email))
    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && view === "email" && <Email
            setView={setView}
            emailPassed={[... new Set(investorsByFund.map(investor => investor.email))]}
            fromView="fund"
            setEmailResponse={setEmailResponse}
        />}
        {!loading && view === "fund" && <div>

            {fund !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Fund: ${fund.name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }


            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setFundId(0)}
                ></img>
                {fund !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    placeContent: "center"
                }}
                    onClick={e => setView("email")}
                >
                    Email
                </button>}
            </div>

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={name}
                    onInput={(e) => {
                        setName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Type{fund === null && <span style={{ color: "red" }}>*</span>}:</div>
                {fund === null &&
                    <span>
                        <Select
                            showSearch
                            mode="multiple"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select type"
                            defaultValue={[]}
                            onChange={handleChangeType}
                            options={typeSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                {fund !== null && <span>
                    {' ' + fund.type}
                </span>}
            </div>

            <div style={entryStyle}>
                <div style={isPhoneSize() ? {
                    display: "flex", flexDirection: "column"
                } : {
                    display: "flex", flexDirection: "row"
                }}>
                    <div>Companies: </div>
                    <span>
                        <Select
                            mode="multiple"
                            style={isPhoneSize() ? {
                                width: '300px'
                            } : {
                                width: '300px', marginLeft: "5px"
                            }}
                            placeholder="select holding companies"
                            defaultValue={holdingCompaniesInvested.map(holdingCompany => holdingCompany.name)}
                            onChange={handleChange}
                            options={selectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>
            </div>

            <div style={entryStyle}>
                Investing Percent:
                {companyIdxSelected.map((companyId, idx) => {
                    return <div key={idx} style={isPhoneSize() ? {
                        display: "flex", flexDirection: "column"
                    } : {
                        display: "flex"
                    }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {holdingCompanies.filter((company) => company.id === companyId)[0].name}:
                        </div>
                        <div>
                            <input style={{
                                outline: 'none',
                                border: "none",
                                borderBottom: "1px solid black",
                                background: 'transparent',
                                textAlign: "right"
                            }}
                                value={investingPercent[companyId] !== undefined ? investingPercent[companyId] : ""}
                                onChange={e => {
                                    // Update state with the raw string input value, allowing "."
                                    const value = e.target.value;

                                    // Set the state with the updated value (as a string)
                                    setDirty(true);
                                    setInvestingPercent({ ...investingPercent, [companyId]: value });
                                }}
                                onBlur={e => {
                                    // Convert to float when input loses focus
                                    const value = parseFloat(e.target.value) || 0; // Default to 0 if invalid
                                    setInvestingPercent({ ...investingPercent, [companyId]: value });
                                }}
                            ></input>%
                        </div>
                    </div>
                })}
            </div>

            <div style={entryStyle}>
                Positions:
                {/* head */}
                <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
                    <div style={{ width: "900px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Investor
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Capital in Date
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {positionsByFund.length > 0 && <div style={{ border: "1px solid black", width: "900px" }}>
                            {
                                positionsByFund
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((position, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>
                                                <div style={{ fontSize: "15px" }}>{position.legal_name}</div>
                                                <div style={{ fontSize: "12px" }}>{position.account_name}</div>
                                            </div>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.amount)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.account_balance)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.funding_receipt_date.slice(0, 10)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.interest_received)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.principal_returned)}
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>

                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div style={entryStyle}>
                Investors:

                {investorsByFund
                    .sort((a, b) => {
                        if (a.legal_name < b.legal_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((investor, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {investor.legal_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Accounts:
                {accountsByFund
                    .sort((a, b) => {
                        if (a.account_name < b.account_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((account, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {account.account_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Assets:
                {propertiesByFund
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div>

            {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveProperty}>
                    Save
                </button>
                {fund !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteFund}
                >
                    Delete
                </button>}
            </div>}
            {saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}>
                    Saving
                </button>
            </div>}
        </div>}
    </>
}