
export const BasicInfoKeyNameMap: Record<string, string> = {
    'name': 'Name',
    'id': "Fund ID",
    // 'nameid': 'Name ID',
    'bank_account': 'Bank Account',
    'bank_name': 'Bank Name',
    'business_address': 'Business Address',
    'ein': 'EIN',
    'document_folder_link': 'Link to Document Folder',
    'entity_type': 'Entity Type',
    'manager_name': 'Manager Name',
    'register_state': 'Register State',
    'soi_exp_date': 'SOI Expiration Date',
    'soi_file_date': 'SOI File Date',
    'status': 'Status',
    'type': 'Type',
    'Use': 'Use',
};

export const TargetOfferingsKeyNameMap: Record<string, string> = {
    // 'acquisition_cost': 'Acquisition Cost',
    // 'actual_raised_debt': 'Actual Raised Debt',
    // 'actual_raised_equity': 'Actual Raised Equity',
    // 'actual_raised_preferred': 'Actual Raised Preferred',
    // 'actual_raised_total': 'Actual Raised Total',
    'target_offerings_debt': 'Target Offerings Debt',
    'target_offerings_equity': 'Target Offerings Equity',
    'target_offerings_preferred': 'Target Offerings Preferred',
    // 'target_offerings_total': 'Target Offerings Total',
    // 'cash_in_hand': 'Cash in Hand',
    // 'cash_spended': 'Cash Spended',
    // 'financing_cost': 'Financing Cost',
    // 'hard_cost': 'Hard Cost',
    // 'holding_cost': 'Holding Cost',
    // 'selling_cost': 'Selling Cost',
    // 'fund_extra_info': 'Fund Extra Info',
    // 'profit': 'Profit',
    // 'revenue': 'Revenue',
};

export const ActualRaisedKeyNameMap: Record<string, string> = {
    'actual_raised_debt': 'Actual Raised Debt',
    'actual_raised_equity': 'Actual Raised Equity',
    'actual_raised_preferred': 'Actual Raised Preferred',
    // 'actual_raised_total': 'Actual Raised Total',
};
