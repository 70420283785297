import arrowLeft from "../assets/img/arrowLeft.png"
import arrowRight from "../assets/img/arrowRight.png"
import close from "../assets/img/close.png"
import { useState } from "react"
import { isPhoneSize } from "utils/util"

export function FundPhotoGallery(props) {
    const { photos } = props
    const [curPhotoIdx, setCurPhotoIdx] = useState(0)
    const [showGallary, setShowGallary] = useState(false)

    function isFirstPage() {
        return curPhotoIdx === 0
    }

    function isLastPage() {
        return curPhotoIdx === photos.length - 1
    }

    return <div>

        <div style={{ display: "flex", placeContent: "center" }}>
            <div style={{ width: "700px", display: "flex", flexFlow: "wrap", placeContent: "center" }}>
                {photos.map((photo, idx) => (
                    <img
                        key={idx}
                        src={photo.url}
                        style={{ height: "100px", margin: "5px", cursor: "pointer" }}
                        onClick={e => {
                            setShowGallary(true)
                            setCurPhotoIdx(idx)
                        }}
                    ></img>
                ))}
            </div>
        </div>

        {photos.length >= 1 && showGallary &&
            <div style={{ position: 'fixed', left: "50%", top: "50%", transform: "translate(-50%, -50%)", height: "100vh", width: "100vw", backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", zIndex: 1 }}

            >
                <div style={{ position: "fixed", bottom: "calc(50vh + 200px)", right: "200px", cursor: "pointer" }}
                    onClick={e => setShowGallary(false)}>
                    <img src={close} style={{ width: "30px" }} onClick={e => setShowGallary(false)}>
                    </img>
                </div>
                {<div style={{ justifyContent: "center", alignItems: "center", display: "flex", position: 'fixed', left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                    <div style={isPhoneSize() ? {
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        position: "relative",
                        left: '12px',
                        zIndex: isLastPage() ? "0" : "1",
                        cursor: isLastPage() ? "auto" : 'pointer'
                    } : {
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        position: "relative",
                        right: '15px',
                        zIndex: isFirstPage() ? "0" : "1",
                        cursor: isFirstPage() ? "auto" : 'pointer'
                    }}
                        onClick={(e) => {
                            e.preventDefault()
                            !isFirstPage() && setCurPhotoIdx(curPhotoIdx - 1)
                        }}
                    >
                        <img style={{ display: isFirstPage() ? "none" : "flex" }} src={arrowLeft}></img>
                    </div>

                    <img style={{ width: isPhoneSize() ? window.innerWidth - 80 : "800px" }} src={photos[curPhotoIdx].url}></img>

                    <div style={isPhoneSize() ? {
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        position: "relative",
                        right: '12px',
                        zIndex: isLastPage() ? "0" : "1",
                        cursor: isLastPage() ? "auto" : 'pointer'
                    } : {
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        position: "relative",
                        left: '15px',
                        zIndex: isLastPage() ? "0" : "1",
                        cursor: isLastPage() ? "auto" : 'pointer'
                    }}
                        onClick={(e) => {
                            e.preventDefault()
                            !isLastPage() && setCurPhotoIdx(curPhotoIdx + 1)
                        }}
                    >
                        <img style={{ display: isLastPage() ? "none" : "flex" }} src={arrowRight}></img>
                    </div>
                </div>}

            </div>
        }

    </div>
}