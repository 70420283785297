
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, Modal, Spin } from 'antd';
import './PDFPreview.css';
import './TextLayer.css';
// import "react-pdf/dist/esm/Page/TextLayer.css";

export function blobToURL(blob: Blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

const PT_PX_RATIO = 1.46
type PDFPreviewProps = {
    url: string
    visible: boolean
    onClose: () => void
}
const PDFPreview: React.FC<PDFPreviewProps> = ({ url, visible, onClose }) => {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const pageRefs = useRef({});
    const [pdf, setPdf] = useState('');
    const [numPages, setNumPages] = useState<number>(0);
    const [currentSignId, setCurrentSignId] = useState('')
    const [loading, setLoading] = useState(false);
    const documentRef = useRef(null)
    const containerRef = useRef(null)
    const WIDTH = 900


    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [visible])

    const reload = async () => {
        setLoading(true)
        //console.log(url)

        const existingPdfBytes = await fetch(url, {
            method: 'GET',
        }).then(res => res.arrayBuffer()).catch(function (err) {
            //console.log('Fetch Error :-S', err);
        });
        const blob = new Blob([new Uint8Array(existingPdfBytes || new ArrayBuffer(0))]);

        const URL: string = await blobToURL(blob) as string;
        setPdf(URL);
        setLoading(false)
    }

    useEffect(() => {
        reload();
    }, [url]);

    return (
        <>
            <div className="pdf-preview-main" style={{ display: visible ? 'block' : 'none' }}>
                <div className="top-nav">
                    <CloseOutlined
                        onClick={onClose}
                        className="close-icon"
                    />
                </div>
                <center className="pdf-container" ref={containerRef}>
                    {!loading ? <Document
                        className="pdf-content"
                        file={pdf}
                        ref={documentRef}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    width={WIDTH}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                    onRenderSuccess={(props) => {

                                    }}
                                >
                                </Page>
                            )
                        )}
                    </Document> : <div className="spin-wrapper"> <Spin /></div>}
                </center>
            </div>
        </>
    );
}

export default PDFPreview;