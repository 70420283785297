import React from 'react';
import { Card } from 'antd';
import { USDollar } from 'utils/format';
import Paragraph from 'antd/es/typography/Paragraph';

export type OfferingCardProps = {
    name: string
    type: string
    link: string
    description?: string
    target_offerings_total: number
    target_offerings_debt?: number
    target_offerings_equity?: number
    target_offerings_preferred?: number
    actual_raised_total?: number
    actual_raised_debt?: number
    actual_raised_equity?: number
    actual_raised_preferred?: number
}

export default ({
    name, type,
    link, description,
    target_offerings_total,
    target_offerings_debt,
    target_offerings_equity,
    target_offerings_preferred,
    actual_raised_total,
    actual_raised_debt,
    actual_raised_equity,
    actual_raised_preferred,
}: OfferingCardProps) => {
    let amountFormatted = USDollar.format(target_offerings_total);
    let fontSize = 24;
    if (amountFormatted.length > 14) {
        fontSize = 20;
    } else if (amountFormatted.length > 20) {
        fontSize = 16
    }

    function getTypeBackgroundColor() {
        if (type.toLowerCase() === "debt") {
            return "blue"
        } else if (type.toLowerCase() === "equity") {
            return "green"
        }
        return "darkcyan"
    }

    function getTypeTagWidth() {
        if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
            return "50px"
        }
        return "80px"
    }

    return <Card hoverable bordered={false} style={{ width: 220, height: 200 }} onClick={() => window.location.href = link}>
        <div style={{ display: "flex", placeContent: "space-between" }}>
            <h4 style={{ margin: 0 }}>{name}</h4>
            {
                type && <div style={{
                    marginRight: "20px",
                    background: getTypeBackgroundColor(),
                    color: "white",
                    display: "flex",
                    borderRadius: "10px",
                    width: getTypeTagWidth(),
                    height: "20px",
                    justifyContent: "center",
                    fontWeight: 'bold',
                }}>
                    {type}
                </div>
            }
        </div>
        <div>Target Offering Total: {amountFormatted}</div>
        <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: 'more' }}>
            {description}
        </Paragraph>
    </Card>
}