import backArrow from "../../assets/img/backArrow.png"
import { useState, useEffect } from "react"
import api from "api"
import { get } from "utils/fetch"
import { Select, Space, message, Spin } from "antd"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { propertyToAddress } from "utils/util"
import { isPhoneSize } from "utils/util"
import { USDollar } from "utils/format"

export function AccountInsertion(props) {
    const { account, setAccountId } = props

    const [messageApi, contextHolder] = message.useMessage();
    const [dirty, setDirty] = useState(false)
    const [saving, setSaving] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [routingNumber, setRoutingNumber] = useState(account === null || account.routing_number === null ? "" : account.routing_number)
    const [accountNumber, setAccountNumber] = useState(account === null || account.account_number === null ? "" : account.account_number)
    const [accountName, setAccountName] = useState(account === null || account.account_name === null ? "" : account.account_name)
    const [bankAddress, setBankAddress] = useState(account === null || account.bank_address === null ? "" : account.bank_address)
    const [bankName, setBankName] = useState(account === null || account.bank_name === null ? "" : account.bank_name)
    const [fundsByAccount, setFundsByAccount] = useState([]) // funds that current account is investing
    const [investorsByAccount, setInvestorsByAccount] = useState([]) // investors that is holding the account
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    const [loading, setLoading] = useState(true)
    const [companiesByAccount, setCompaniesByAccount] = useState([])
    const [propertiesByAccount, setPropertiesByAccount] = useState([])
    const [positionsByAccount, setPositionsByAccount] = useState([])

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFundsByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.fundsByAccount(id), setFundsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchCompaniesByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.companiesByAccount(id), setCompaniesByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchPropertiesByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.propertiesByAccount(id), setPropertiesByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        // setLoading(false)
    }

    async function fetchInvestorByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.investorsByAccount(id), setInvestorsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchPositionsByAccount(id) {
        await get(api.manager.positionsByAccount(id), setPositionsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    useEffect(() => {
        // if account is not null
        if (account !== null) {
            // retrieve funds associate with it
            fetchPositionsByAccount(account.id)
            fetchFundsByAccount(account.id)
            fetchCompaniesByAccount(account.id)
            fetchPropertiesByAccount(account.id)
            fetchInvestorByAccount(account.id)
        } else {
            setLoading(false)
        }
    }, [])

    function canSubmit() {
        var invalidFields = []
        if (accountName.trim().length === 0) {
            invalidFields.push("Please fill in account name.")
        }
        if (bankName.trim().length === 0) {
            invalidFields.push("Please fill in bank name.")
        }
        if (accountNumber.trim().length === 0) {
            invalidFields.push("Please fill in account number.")
        }
        if (routingNumber.trim().length === 0) {
            invalidFields.push("Please fill in routing number.")
        }
        if (bankAddress.trim().length === 0) {
            invalidFields.push("Please fill in bank address.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveAccount() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            setAccountId(0)
            return
        }
        setSaving(true)
        setAccountName(accountName.trim())
        setBankName(bankName.trim())
        setAccountNumber(accountNumber.trim())
        setRoutingNumber(routingNumber.trim())
        setBankAddress(bankAddress.trim())
        const body = JSON.stringify({
            accountName,
            bankName,
            accountNumber,
            routingNumber,
            bankAddress,
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            body
        }
        if (account === null) {
            fetch(api.manager.accounts, options);
        } else {
            fetch(api.manager.account(account.id), options);
        }
        setAccountId(0)
    }

    // called by DeleteConfirmation component to delete current record
    // async function deletionCallback() {
    //     const options = {
    //         method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    //         mode: "cors", // no-cors, *cors, same-origin
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         // body
    //     }
    //     const result = await fetch(api.manager.property(property.id), options);
    //     const data = await result.json()
    //     setPropertyId(0)
    // }

    function deleteAccount() {
        if (investorsByAccount.length > 0 || fundsByAccount.length > 0) {
            setCreationErrorMessages([
                ...investorsByAccount
                    .map(investor => {
                        return `Current account is being held by ${investor.legal_name}.`
                    }),
                ...fundsByAccount
                    .map(fund => {
                        return `Current account is investing ${fund.name}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json"
            },
            // body
        }
        const result = await fetch(api.manager.account(account.id), options);
        const data = await result.json()
        setAccountId(0)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <div>

            {account !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Account: ${account.account_name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setAccountId(0)}
                ></img>
            </div>

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Account Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={accountName}
                    onInput={(e) => {
                        setAccountName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Bank Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={bankName}
                    onInput={(e) => {
                        setBankName(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Account Number<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={accountNumber}
                    onInput={(e) => {
                        setAccountNumber(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Routing Number<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={routingNumber}
                    onInput={(e) => {
                        setRoutingNumber(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Bank Address<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={bankAddress}
                    onInput={(e) => {
                        setBankAddress(e.target.value)
                        setDirty(true)
                    }}></input>
            </div>

            <div style={entryStyle}>
                Positions:
                {/* head */}
                <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
                    <div style={{ width: "1050px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Fund
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Capital in Date
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {positionsByAccount.length > 0 && <div style={{ border: "1px solid black", width: "1050px" }}>
                            {
                                positionsByAccount
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((position, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>
                                                {position.fund_name}
                                            </div>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.amount)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.account_balance)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.funding_receipt_date.slice(0, 10)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.interest_received)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.principal_returned)}
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>

                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div style={entryStyle}>
                Investors:
                {investorsByAccount
                    .sort((a, b) => {
                        if (a.legal_name < b.legal_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((investor, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {investor.legal_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Entities:
                {fundsByAccount
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((fund, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {fund.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Holding Companies:
                {companiesByAccount
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((company, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {company.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Assets:
                {propertiesByAccount
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{}}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div>

            {!saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveAccount}>
                    Save
                </button>

                {account !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteAccount}
                >
                    Delete
                </button>}
            </div>}

        </div>}
    </>
}