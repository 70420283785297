import React, { useEffect, useState } from 'react';
import { TabComponentProps } from 'types/common';
import _ from 'lodash';
import { Col, Descriptions, Spin, Table, Typography, message } from 'antd';
import api from 'api';
import { get } from 'utils/fetch';
import { makeTableDataSource } from 'utils/format';
import { InvestmentInfoKeyNameMap } from './const';

const { Text } = Typography;

const BasicInfo: React.FC<TabComponentProps> = ({ nameid }) => {
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    async function fetchPropertyInvestmentInfo(nameid: string) {
        setLoading(true);
        await get(
            api.property.getBasicInfo(nameid),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchPropertyInvestmentInfo(nameid);
        }
    }, [nameid]);

    let investmentSection = null;
    if (loading) {
        investmentSection = <Spin />;
    }
    else if (!nameid) investmentSection = <span>Please enter a property id from selection</span>;
    else if (nameid && basicInfo?.address) investmentSection = (
        <>
            <h3>Investment Info</h3>
            <Table bordered pagination={false} columns={[{
                title: 'Property Investment Info',
                dataIndex: 'name'
            }, {
                title: 'Detail',
                dataIndex: 'value'
            }]} dataSource={makeTableDataSource(InvestmentInfoKeyNameMap, basicInfo)} />
        </>
    );

    return <>
        {contextHolder}
        {investmentSection}
    </>;
}

export default BasicInfo;