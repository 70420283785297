import { USDollar } from "./format";
export function isPhoneSize() {
    return window.innerWidth <= 480
}

function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getUserPermissions() {
    const fief_authstate = sessionStorage.getItem('fief-authstate')
    if (fief_authstate === null) return []
    const value = JSON.parse(fief_authstate);
    if (value.tokenInfo === null) return []
    const token = value.tokenInfo.access_token
    const permissions = decodeJWT(token).permissions
    return permissions
}

export function isAdmin() {
    return getUserPermissions().includes("fief:admin")
}

export function isInvestor() {
    return !isAdmin()
}

export function propertyToAddress(property) {
    return property.address2 !== null && property.address2.trim().length > 0 ?
        `${property.address} ${property.address2}, ${property.city}, ${property.state} ${property.zip}` :
        `${property.address}, ${property.city}, ${property.state} ${property.zip}`
}

export function investmentStructToName(investment) {
    // var ret = `${investment.legal_name}`
    // if (investment.account_name !== null && investment.account_name.length > 0) {
    //     ret += ` (${investment.account_name})`
    // }
    var ret = `${investment.account_name}`
    ret += ` - ${investment.fund_name} (${investment.funding_receipt_date.slice(0, 10)} - ${investment.actual_redemption_date.slice(0, 10)})`
    return ret
}

export function investorStructToDisplayedName(investor) {
    return `${investor.legal_name} (${investor.email})`
}

export function fundEntityToName(fund) {
    return fund.name
}

export function getTodayDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function getTomorrowDateString() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(tomorrow.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function sendEmail(buttonText, amount, email, name, type) {
    const subject = buttonText === "Pay" ? "Interest Paid" : "Interest Unpaid";
    const body = buttonText === "Pay"
        ? `Hi ${name}, We have sent you ${USDollar.format(amount)} of ${type}.`
        : `Hi ${name}, We have unpaid ${USDollar.format(amount)} of ${type}.`;

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}