import React, { useEffect, useState } from 'react';
import { TabComponentProps } from 'types/common';
import _ from 'lodash';
import { Col, Descriptions, DescriptionsProps, Divider, Grid, Row, Spin, Table, Typography, message } from 'antd';
import api from 'api';
import { get } from 'utils/fetch';
import { USDollar, makeDescriptionItems } from 'utils/format';
import { BasicInfoKeyNameMap, TitleInfoKeyNameMap } from './const';

const { Text } = Typography;

const BasicInfo: React.FC<TabComponentProps> = ({ nameid }) => {
    const [basicInfo, setBasicInfo] = useState<Record<string, any>>({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    async function fetchPropertyBasicInfo(nameid: string) {
        setLoading(true);
        await get(
            api.property.getBasicInfo(nameid),
            setBasicInfo,
            (errors: any[]) => errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        );
        setLoading(false);
    }

    useEffect(() => {
        if (nameid) {
            fetchPropertyBasicInfo(nameid);
        }
    }, [nameid]);

    let basicSection = null;
    let titleInfoSection = null;
    if (loading) {
        basicSection = <Spin />;
    }
    else if (!nameid) basicSection = <span>Please enter a property id from selection</span>;
    else if (nameid && basicInfo?.address) {
        basicSection = <>
            <h3>Basics</h3>
            <Descriptions bordered items={makeDescriptionItems(BasicInfoKeyNameMap, basicInfo)} />
        </>;
        titleInfoSection = <>
            <Divider />
            <h3>Proprety Title</h3>
            <Descriptions bordered items={makeDescriptionItems(TitleInfoKeyNameMap, basicInfo)} />
        </>;
    }

    return <>
        {contextHolder}
        {basicSection}
        {titleInfoSection}
    </>;
}

export default BasicInfo;