import { Button, Layout, Row } from 'antd';
import { useFiefAuth, useFiefIsAuthenticated, useFiefUserinfo } from '@fief/fief/react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Settings: React.FC = () => {
    const fiefAuth = useFiefAuth();
    const isAuthenticated = useFiefIsAuthenticated();
    const userinfo = useFiefUserinfo();
    const navigate = useNavigate();

    const logout = useCallback(async () => {
        try {
            // Get the base URL of your application
            const baseUrl = `${window.location.protocol}//${window.location.host}`;

            // Perform Fief logout with redirect URL
            await fiefAuth.logout(baseUrl + '/logout');
        } catch (error) {
            console.error("Fief logout failed:", error);
        } finally {
            // Clear local storage
            localStorage.removeItem('token');
            // Clear any other auth-related state here

            // Navigate to login page
            navigate('/logout');
        }
    }, [fiefAuth, navigate]);

    return (
        <Layout>
            <Row>
                {isAuthenticated && userinfo && (
                    <Button type="primary" onClick={logout} danger>
                        Logout
                    </Button>
                )}
            </Row>
        </Layout>
    );
};

export default Settings;