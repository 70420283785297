import { Button, Card } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';

type OfferingTileProps = {
    title: string
    description: string
    link: string
    imgSrc: string
    type: string
    disabled?: boolean
}

function getTypeBackgroundColor(type: string) {
    if (type.toLowerCase() === "debt") {
        return "blue"
    } else if (type.toLowerCase() === "equity") {
        return "green"
    }
    return "darkcyan"
}

function getTypeTagWidth(type: string) {
    if (type.toLowerCase() === "debt" || type.toLowerCase() === "equity") {
        return "50px"
    }
    return "80px"
}

const OfferingTile: React.FC<OfferingTileProps> = ({
    title,
    type,
    description,
    link,
    imgSrc,
    disabled,
}) => {
    return <div>
        <div style={{ display: 'flex', justifyContent: "right", marginRight: "7px" }}>
            {
                type && <div style={{
                    background: getTypeBackgroundColor(type),
                    color: "white",
                    display: "flex",
                    width: getTypeTagWidth(type),
                    justifyContent: "center",
                    fontWeight: 'bold',
                    borderRadius: "5px 5px 0 0"
                }}>
                    {type}
                </div>
            }
        </div>
        <Card style={{
            width: 200,
            cursor: "default"
        }} styles={{
            title: {
                color: disabled ? 'gray' : 'auto',
            },
            body: {
                height: 250,
                display: 'flex',
                flexDirection: 'column',
                cursor: disabled ? 'not-allowed' : 'default',
            }
        }} title={title + (disabled ? ' (Inactive)' : '')} hoverable={!disabled} bordered={false}>

            <div style={{
                // backgroundImage: 'url(' + imgSrc + ')' + (disabled ? ', linear-gradient(to bottom, grey, grey)' : ''),
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                width: '100%',
                height: 100,
            }} >
                <img style={{ width: "100%", height: '100%' }} src={imgSrc}></img>
            </div>
            <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: 'more' }}
                style={{ width: '100%', color: disabled ? 'gray' : 'inherit' }}>
                {description}
            </Paragraph>

            <Button style={{ width: '100%', margin: 'auto' }}
                type="primary"
                disabled={disabled}
                onClick={() => window.location.href = link}
            >
                View More
            </Button>
        </Card>
    </div>
}

export default OfferingTile