import React from 'react';
import { NameItem } from 'types/investor';

type LinkProps = {
    nameid: string
    text: string
}

export const FundLink: React.FC<LinkProps> = ({ nameid, text}) => {
    if (!nameid) return <span>-</span>;
    const searchParams = new URLSearchParams();
    searchParams.append("nameid", nameid);
    return <a target='_blank' href={`/fund?${searchParams.toString()}`}>{text}</a>
}

export const InvestorLink: React.FC<LinkProps> = ({ nameid, text}) => {
    if (!nameid) return <span>-</span>;
    const searchParams = new URLSearchParams();
    searchParams.append("nameid", nameid);
    return <a target='_blank' href={`/investor?${searchParams.toString()}`}>{text}</a>
}

export const PropertyLink: React.FC<LinkProps> = ({ nameid, text}) => {
    if (!nameid) return <span>-</span>;
    const searchParams = new URLSearchParams();
    searchParams.append("nameid", nameid);
    return <a target='_blank' href={`/property?${searchParams.toString()}`}>{text}</a>
}
