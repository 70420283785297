import api from 'api';

const SCHEMA = 'http'

type ErrorType = {
    message: string
    [x: string]: unknown;
}

export async function get(
    apiPath: string,
    successCallback: (data: any) => any,
    failCallback: (errors: ErrorType[]) => any,
) {
    // const delimiter = apiPath.startsWith('/') ? '' : '/';
    // const hostName = process.env.REACT_APP_BACKEND_HOST
    // const portNumber = process.env.REACT_APP_BACKEND_PORT
    let response = await fetch(apiPath);
    const { data, errors = [] } = await response.json();
    if (data) successCallback(data);
    else if (errors && errors.length > 0) failCallback(errors);
    successCallback(data)
};


export async function getFile(
    apiPath: string,
    successCallback: (data: any) => any,
    failCallback: (errors: any) => any,
) {
    const delimiter = apiPath.startsWith('/') ? '' : '/';
    const hostName = process.env.REACT_APP_BACKEND_HOST
    const portNumber = process.env.REACT_APP_BACKEND_PORT
    try {
        let response = await fetch(`${SCHEMA}://${hostName}:${portNumber}${delimiter}${apiPath}`);
        const fileData = await response.blob();
        if (fileData) successCallback(fileData);
    } catch (e) {
        failCallback([e]);
        return
    }
};

export async function post(
    apiPath: string,
    payload: any,
    successCallback: (data: any) => any,
    failCallback: (errors: any) => any
) {
    // const delimiter = apiPath.startsWith('/') ? '' : '/';
    // const hostName = process.env.REACT_APP_BACKEND_HOST
    // const portNumber = process.env.REACT_APP_BACKEND_PORT
    // let url = `${SCHEMA}://${hostName}:${portNumber}${delimiter}${apiPath}`;
    let response = await fetch(apiPath, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(payload),
    });
    const { data, errors = [] } = await response.json();

    if (data) successCallback(data);
    else if (errors && errors.length > 0) failCallback(errors);
};