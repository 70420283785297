import backArrow from "../../assets/img/backArrow.png"
import { useState, useRef, useEffect } from "react";
import { Spin, message } from "antd";
import './EmailInput.css';
import api from "api";
import { get } from "utils/fetch";
import PdfIcon from "../../assets/img/PdfIcon.png"

export function Email(props) {
    const { setView, emailPassed, fromView, setEmailResponse } = props

    const [messageApi, contextHolder] = message.useMessage();
    const [emails, setEmails] = useState(emailPassed);
    const [inputEmail, setInputEmail] = useState('');
    const [subject, setSubject] = useState('')
    const textAreaRef = useRef(null);
    const [content, setContent] = useState("")
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([]);

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // console.log(content)

    async function fetchSubjectTemplate() {
        await get(api.email.subjectTemplate, setSubject, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
    }

    async function fetchPdf() {
        // Fetch the PDF from the FastAPI backend
        fetch(api.email.pdfTemplate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            }
        })
            .then(response => {
                // Get filename from Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                // console.log(contentDisposition)
                const matches = contentDisposition && contentDisposition.match(/filename=(.+)/);
                // console.log(contentDisposition.match(/filename=(.+)/))
                const filename = matches ? matches[1] : "default.pdf";  // Fallback if no filename
                return response.blob().then(blob => {
                    const url = URL.createObjectURL(blob);
                    setFiles([...files, { filename, url, blob }])
                })
            })
            .catch(err => console.error('Error fetching PDF:', err));
    }

    async function fetchContentTemplate() {
        await get(api.email.contentTemplate, setContent, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        fetchSubjectTemplate()
        fetchContentTemplate()
        fetchPdf()
    }, [])

    // Handle input change
    const handleInputChange = (e) => {
        setInputEmail(e.target.value);
    };

    // Handle key down (when pressing "Enter")
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputEmail.trim() !== '') {
            // Basic email validation
            if (validateEmail(inputEmail.trim())) {
                setEmails([...emails, inputEmail.trim()]);
                setInputEmail(''); // Clear input after adding email
            } else {
                alert('Please enter a valid email address');
            }
            e.preventDefault();
        }
    };

    // Validate email using a basic regex
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Remove email from the list
    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    useEffect(() => {
        // Adjust the height based on the scroll height
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto"; // Reset height to auto
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"; // Set to scroll height
        }
    }, [content]); // Re-run whenever the content changes

    async function sendEmail() {
        if (emails.length === 0) {
            alert("Please add recipients")
            return
        }
        setEmailResponse("Sending...")
        setView(fromView)
        // Create a FormData object
        const formData = new FormData();
        // formData.append('files', files.map(file => new File([file.blob], file.filename, { type: 'application/pdf' })))
        files.map(file => formData.append('files', new File([file.blob], file.filename, { type: 'application/pdf' })))
        // formData.append('to_email', emails);  // Append the recipient's email
        emails.map(email => formData.append("to_emails", email.trim()))
        formData.append('subject', subject);    // Append the email subject
        formData.append('content', content);    // Append the email content

        // Send the email with the PDF attachment
        const emailResponse = await fetch(api.email.sendEmail, {
            method: 'POST',
            body: formData
        });

        const emailResult = await emailResponse.json();
        setEmailResponse(emailResult.message)
        // Hide the message after 1 second
        setTimeout(() => {
            setEmailResponse('');
        }, 1000);
        console.log(emailResult);
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <>
            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setView(fromView)}
                ></img>
            </div>
            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    {emails.map((email, index) => (
                        <div key={index} className="email-chip">
                            {email}
                            <span className="remove-btn" onClick={() => removeEmail(index)}>
                                &times;
                            </span>
                        </div>
                    ))}
                    <input
                        type="text"
                        value={inputEmail}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter email addresses"
                        className="email-input"
                        style={{ width: `${Math.max(20, inputEmail.length * 10)}px` }}  // Dynamically resize input
                    />
                </div>
            </div>

            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    <input
                        type="text"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        // onKeyDown={handleKeyDown}
                        placeholder="Subject..."
                        className="email-input"
                        style={{ width: `100%` }}  // Dynamically resize input
                    />
                </div>
            </div>

            <div className="email-input-container" style={{ width: "100%" }}>
                <div className="emails" style={{ width: "100%" }}>
                    <textarea
                        type="text"
                        ref={textAreaRef}
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        // onKeyDown={handleKeyDown}
                        placeholder="Content..."
                        className="email-input"
                        style={{ width: `100%`, minHeight: "500px", fontFamily: "Arial, sans-serif" }}  // Dynamically resize input
                    />
                </div>
            </div>

            {files && <div>
                {files.map((file, idx) =>
                    <a key={idx} href={file.url} target="_blank" rel="noreferrer noopener">
                        {/* <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" /> */}
                        <div style={{ display: "flex", alignItems: "center", fontSize: "15px", color: "black" }}>
                            <div style={{ padding: "5px", paddingBottom: "0" }}>
                                <img src={PdfIcon} style={{ width: "14px" }}></img>
                            </div>
                            {file.filename}
                        </div>
                    </a>
                )
                }
            </div>}

            <button style={{
                height: "30px",
                width: "50px",
                borderRadius: "30px",
                background: "rgb(17 32 86)",
                color: "white",
                cursor: "pointer",
            }}
                onClick={sendEmail}
            >
                Send
            </button>
        </>
        }
    </>
}