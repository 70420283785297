// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investor-select {
    width: 300px;
}
.investor-confirm-button {
    margin-left: 10px;
}
.investor-page {
    width: 100%;
}
.tabs {
    width: 100%;
}

.ant-table-tbody > tr:nth-child(odd) {
    background: #F1E6FF;
}

.ant-table-tbody > tr:nth-child(even) {
    background: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/Investor/investor.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".investor-select {\n    width: 300px;\n}\n.investor-confirm-button {\n    margin-left: 10px;\n}\n.investor-page {\n    width: 100%;\n}\n.tabs {\n    width: 100%;\n}\n\n.ant-table-tbody > tr:nth-child(odd) {\n    background: #F1E6FF;\n}\n\n.ant-table-tbody > tr:nth-child(even) {\n    background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
