import React, { useState } from 'react';
import { Row } from 'antd';
import pdfSVG from 'assets/img/pdf.svg';
import PDFPreview from 'components/PDFPreview';

type ProjectUpdatesType = {
    nameid: string
}

const ProjectDocuments: React.FC<ProjectUpdatesType> = ({ nameid }) => {
    const [pdfVisible, setPDFVisible] = useState<boolean>(true);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    return <>
        <Row>
            <a  onClick={() => { setPdfUrl('/api/project/getpdf'); setPDFVisible(true) }}>
                <img src={pdfSVG} style={{ height: 25, marginRight: 5, marginTop: -2 }}></img>
                Document Title1
            </a>
        </Row>
        {pdfUrl && <PDFPreview
            url={pdfUrl}
            visible={pdfVisible}
            onClose={() => setPDFVisible(false)}
        />}
    </>
};

export default ProjectDocuments;