import { isPhoneSize } from "utils/util"

export function OfferingDetailedTab(props) {
    const { text } = props
    return <div style={{ width: `${text.length * 100}%`, display: "flex", justifyContent: "center", background: "transparent" }}>
        <a href={`#${text}`}>
            <div style={{
                fontWeight: "bold",
                fontSize: isPhoneSize() ? "17px" : "20px",
                color: "black"
            }}>
                {text}
            </div>
        </a>
    </div>
}