import { useEffect, useState } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input } from "antd";
import { FundInsertion } from "pages/RecordInsertion/FundInsertion";

export function FundManager() {

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [funds, setFunds] = useState([])
    const [holdingCompanies, setHoldingCompanies] = useState([])
    const [fundId, setFundId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFunds() {
        await get(api.manager.funds, setFunds, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    async function fetchHoldingCompanies() {
        await get(api.manager.holdingCompanies, setHoldingCompanies, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        if (fundId !== 0) return
        fetchFunds()
        fetchHoldingCompanies()
    }, [fundId])

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && fundId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on fund name"
                />
            </div>

            <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setFundId(-1)}>
                    Add
                </button>
            </div>

            {funds
                .filter(fund =>
                    fund.name && fund.name.toLowerCase().includes(filteredText))
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 1
                })
                .map((fund, idx) => {
                    return <div key={idx} style={{
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        borderBottom: "1px solid blue",
                        cursor: "pointer"
                    }}
                        onClick={() => setFundId(fund.id)}
                    >
                        {`${fund.name}`}
                    </div>
                })}

        </>
        }
        {!loading && fundId > 0 && <>
            <FundInsertion
                fund={funds.filter(fund => fund.id === fundId)[0]}
                setFundId={setFundId}
                holdingCompanies={holdingCompanies} />
        </>}
        {!loading && fundId < 0 && <>
            <FundInsertion fund={null}
                setFundId={setFundId}
                holdingCompanies={holdingCompanies} />
        </>}
    </>
}