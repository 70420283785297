import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, message, AutoComplete, Input, Tabs, Layout } from "antd";
import { PropertyData } from 'types/property';
import BasicInfo from './BasicInfo';
import { get } from 'utils/fetch';
import api from 'api';
import './property.css';
import PropertyFinancials from './PropertyFinancials';
import PropertyInvestment from './PropertyInvestment';
import Rental from './Rental';
import PITI from './PITI';
import Loan from './Loan';
import Acquisition from './Acquisition';
import DesignAndDevelopment from './DesignAndDevelopment';
import InteriorDesign from './InteriorDesign';
import Construction from './Construction';
import Sale from './Sale';

const Property: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [properties, setProperties] = useState<PropertyData[]>([]);
    const [fundID, setFundID] = useState<number>(0);
    const [activeTabKey, setActiveTabKey] = useState<string>('basic_info');
    const [propertyNameIDInputValue, onPropertyNameIDInputValueChange] = useState<string>('');
    const [propertyNameID, setPropertyNameID] = useState<string>('');
    const [messageApi, contextHolder] = message.useMessage();

    const displayErrorMessage = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchProperties() {
        await get(api.property.list, setProperties, (errors: any[]) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }));
    }

    const tabItems = [
        {
            key: 'basic_info',
            label: 'Basic Info',
            children: <BasicInfo fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'investment',
            label: 'Investment Info',
            children: <PropertyInvestment fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'financials',
            label: 'Financials',
            children: <PropertyFinancials fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'acquisition',
            label: 'Acquisition Info',
            children: <Acquisition fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'loan',
            label: 'Loans',
            children: <Loan fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'piti',
            label: 'PITI Info',
            children: <PITI fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'rental',
            label: 'Rental Info',
            children: <Rental fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'design_and_development',
            label: 'Design and Development',
            children: <DesignAndDevelopment fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'interior_design',
            label: 'Interior Design',
            children: <InteriorDesign fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'construction',
            label: 'Construction',
            children: <Construction fund_id={fundID} nameid={propertyNameID} />,
        },
        {
            key: 'sale',
            label: 'Sale',
            children: <Sale fund_id={fundID} nameid={propertyNameID} />,
        },
    ];

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        if (searchParams.get('nameid')) {
            if (properties.find(i => i.nameid === searchParams.get('nameid'))) {
                if (tabItems.find(i => i.key === searchParams.get('tabKey'))) {
                    setActiveTabKey(searchParams.get('tabKey') || 'basic_info');
                } else {
                    setActiveTabKey('basic_info');
                }
                setPropertyNameID(searchParams.get('nameid') || '');
                onPropertyNameIDInputValueChange(searchParams.get('nameid') || '');
            } else if (properties.length > 0) {
                messageApi.error("Please enter a valid investor name ID");
            }
        }
    }, [searchParams, properties]);

    const onNameIDSearch = (value: string) => {
        if (!properties.find(x => x.nameid === value)) {
            messageApi.error("Please enter a valid property name ID");
            return;
        }
        setPropertyNameID(value);
    }

    return <Layout>
        {contextHolder}

        <h3>Select a property to view</h3>
        <div className="property-choose">
            <AutoComplete
                value={propertyNameIDInputValue}
                onChange={onPropertyNameIDInputValueChange}
                options={properties.map((x) => ({
                    value: x.nameid,
                    label: `${x.address} (${x.nameid})`,
                })).filter((x) => x.label.toLowerCase().includes(
                    propertyNameIDInputValue.trim().toLowerCase())
                )}
            >
                <Input.Search
                    onSearch={onNameIDSearch}
                    size="large"
                    placeholder="Search property name ID"
                />
            </AutoComplete>
        </div>
        <Divider />
        <div className='tabs'>
            <Tabs
                tabBarStyle={{ maxWidth: 1000, width: '100%' }}
                defaultActiveKey="basic_info"
                tabPosition="top"
                activeKey={activeTabKey}
                onChange={(val) => setActiveTabKey(val)}
                items={tabItems}
            />
        </div>
    </Layout>
}

export default Property;
